export const API_KEY = 'API_KEY=fHUuioTyELUantbGA7O4qP7NkY6JMdE8';
export const ENGLISH = 'english';
export const ARABIC = 'arabic';

export const STATUS_ACTIVE = 'Active';
export const VALUE_ACTIVE = true;
export const STATUS_IN_ACTIVE = 'in_active';
export const VALUE_IN_ACTIVE = false;

export const ATTEND = 'attend';
// approval status
export const PENDING = 'pending';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const REVOKED = 'revoked';

export const STATUS_PENDING = 'Pending';
export const STATUS_APPROVED = 'Approved';
export const STATUS_REJECTED = 'Rejected';
export const STATUS_REVOKED = 'Revoked';

export const JAMELAH = 'Jamelah';
export const SERVICE = 'service';
export const PRODUCT = 'product';
export const supportAgentIdentity = '5c3cad21dc6a6514b0a53d33';

export const BOTH = 'both';

export const PROMOTIONAL = 'promotional';

export const CURRENCY = 'SAR';

export const ADMIN = 'admin';
export const MARKETER = 'Marketer';
export const ARTIST = 'Artist';
export const ARTIST_EMPLOYEE = 'artistEmployee';

export const DRIVER = 'Driver';
export const DOCTOR = 'Doctor';
export const CO_ORDINATOR = 'Co-ordinator';
export const USER = 'User';

export const CHAT_MESSAGE_TYPE_TEXT = 'Text';
export const CHAT_MESSAGE_TYPE_IMAGE = 'Image';
export const CHAT_MESSAGE_TYPE_LOCATION = 'Location';

export const OFFER_PRODUCT = 'offer_product';

export const STATUS_WAITING_ARTIST_RESPONSE = 'Waiting Artist Response';
export const STATUS_USER_CANCELLED_BEFORE_ARTIST_ACCEPT = 'User Cancelled Before Artist Accept';
export const STATUS_USER_CANCELLED_NEW_TIME = 'User Cancelled NewTime';
export const STATUS_USER_ACCEPTED_NEW_TIME = 'User Accepted NewTime';
export const STATUS_USER_CANCELLED_PAYMENT = 'User Cancelled Payment';
export const STATUS_USER_CANCELLED_AFTER_PAYMENT = 'User Cancelled After Payment';
export const STATUS_ACCEPTED_WAITING_PAYMENT = 'Artist Accepted Waiting payment';
export const STATUS_SUGGESTED_NEW_TIME = 'Suggested NewTime';
export const STATUS_ARTIST_CANCELLED_AFTER_PAYMENT = 'Artist Cancelled After Payment';
export const STATUS_PAID_PAYMENT = 'paid_confirmed';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_DELIVERED = 'Delivered';

export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_EXPIRED_NO_USER_RESPONSE = 'Expired No User Resp';
export const STATUS_EXPIRED_NO_PAYMENT = 'Expired No Payment';
export const STATUS_EXPIRED_NO_ARTIST_RESPONSE = 'Expired No Artist Resp';

export const STATUS_ARTIST_CANCELLED = 'Artist Cancelled';
export const STATUS_ADMIN_CANCELLED = 'Order Cancelled by Customer Service';
export const STATUS_USER_CANCELLED = 'User Cancelled';
export const STATUS_RESCHEDULE_REQUESTED_BY_PROVIDER = 'Reschedule requested by provider';
export const STATUS_RESCHEDULE_REQUESTED = 'Reschedule requested, waiting for artist response';
export const STATUS_RESCHEDULE_REQUEST_ACCEPTED = 'Paid and confirmed and rescheduling accepted';
export const STATUS_RESCHEDULE_REQUEST_REJECTED = 'Reschedule Request Rejected';
export const STATUS_RESCHEDULE_REQUEST_EXPIRED = 'Reschedule Request Expired';

export const STATUS_ALL = 'All Status';
export const ALL = 'all';

export const SELECT_LABEL = 'Select';
export const SELECT_VALUE = 'select';

export const STATUS_ARABIC = 'Arabic';
export const STATUS_ENGLISH = 'English';

export const TRANSACTION_STATUS_RCVD_FROM_USER = 'received_from_user';
export const TRANSACTION_STATUS_RFND_TO_USER_WALLET = 'refunded_to_user_wallet';
export const TRANSACTION_STATUS_CANCEL_CHARGE = 'cancellation_charge';

export const TRANSACTION_STATUS_PAYMENT_RECIEVED = 'payment_received_from_user';
export const TRANSACTION_STATUS_CREDITED = 'credited_to_wallet';
export const TRANSACTION_STATUS_DEBITED = 'debited_from_wallet';
export const TRANSACTION_STATUS_TRNSFD_TO_ACCOUNT = 'transferred_to_account';
export const TRANSACTION_STATUS_REFUND_TO_ACCOUNT = 'refund_to_account';
export const TRANSACTION_STATUS_WALLET_RECHARGED = 'user_wallet_recharge';
export const TRANSACTION_STATUS_COUPON_DISCOUNT = 'promo_coupon_discount';
export const TRANSACTION_STATUS_MARKETER_COMMISSION = 'marketer_commission'; // coupon
export const TRANSACTION_STATUS_MARKETER_PROMO_CODE_COMMISSION = 'marketer_promo_code_commission';

export const TRANSACTION_STATUS_OFFER_DISCOUNT = 'offer_discount';
export const TRANSACTION_STATUS_GIFT_ORDER_DISCOUNT = 'gift_order_discount';
export const TRANSACTION_STATUS_RESELLER_CODE_DISCOUNT = 'reseller_code_discount';
export const TRANSACTION_STATUS_PAYFORT_COMMISSION = 'payfort_commission';
export const TRANSACTION_STATUS_TAX_FOR_PAYFORT_COMMISSION = 'tax_for_payfort_commission';
export const TRANSACTION_STATUS_JAMELAH_COMPLETION_COMMISSION = 'jamelah_completion_commission';
export const TRANSACTION_STATUS_JAMELAH_CANCELLATION_COMMISSION = 'jamelah_cancellation_commission';
export const TRANSACTION_STATUS_BANK_TRANSFER_FEE = 'payment_transfer_bank_fee';
export const TRANSACTION_STATUS_RESELLER_WALLET_RECHARGE = 'reseller_wallet_recharge';

export const DEFAULT_BANK_NAME = 'Saudi British Bank (SABB)';

export const TRANSACTION_TYPE_CREDIT = 'credit';
export const TRANSACTION_TYPE_DEBIT = 'debit';
export const TRANSACTION_TYPE_TRANSFER = 'transfer';

export const ARTIST_SERVICE_INCOME = 'Service Income';
export const ARTIST_CANCELLATION_CHARGES = 'Order cancellation charges';

// request status
export const WAITING_ARTIST_RESPONSE = 'waitingArtistResp';
export const USER_CANCELLED_BEFORE_ARTIST_ACCEPT = 'userCancelledBeforeArtistAccept';
export const USER_CANCELLED_NEW_TIME = 'userCancelledNewTime';
export const USER_ACCEPTED_NEW_TIME = 'userAcceptedNewTime';
export const USER_CANCELLED_PAYMENT = 'userCancelledPayment';
export const USER_CANCELLED_AFTER_PAYMENT = 'userCancelledAfterPayment';
export const ACCEPTED_WAITING_PAYMENT = 'acceptedWaitingPayment';
export const SUGGESTED_NEW_TIME = 'suggestedNewTime';
export const ARTIST_CANCELLED_AFTER_PAYMENT = 'artistCancelledAfterPayment';
export const ARTIST_CANCELLED = 'artistCancelled';
export const USER_CANCELLED = 'userCancelled';
export const ADMIN_CANCELLED = 'adminCancelled';
export const PAID_PAYMENT = 'paidPayment';
export const PAID_AND_CONFIRMED = 'paid_and_confirmed';
export const COMPLETED = 'completed';
export const DELIVERED = 'delivered';

export const ORDERED = 'ordered';
export const CANCELLED = 'cancelled';
export const RECIEVED = 'recieved';

export const COMPLETED_AND_PAID = 'completed_and_paid';
export const COMPLETED_AND_UNPAID = 'completed_and_unpaid';
export const IN_PROGRESS = 'inProgress';
export const EXPIRED_NO_USER_RESPONSE = 'expiredNoUserResp';
export const EXPIRED_NO_PAYMENT = 'expiredNoPayment';
export const EXPIRED_NO_ARTIST_RESPONSE = 'expiredNoArtistResp';
export const PARTIALLY_REFUNDED = 'partiallyRefunded';
export const RESCHEDULE_REQUESTED = 'reschedule_requested';
export const RESCHEDULE_REQUEST_ACCEPTED = 'reschedule_request_accepted';
export const RESCHEDULE_REQUEST_REJECTED = 'reschedule_request_rejected';
export const RESCHEDULE_REQUEST_CANCELLED = 'reschedule_request_cancelled';
export const RESCHEDULE_REQUEST_CANCELLED_BY_PROVIDER = 'reschedule_request_cancelled_by_provider'; // cancelled by provider
export const SHIPPED = 'shipped';

export const RESCHEDULE_REQUEST_EXPIRED = 'reschedule_request_expired';
export const RESCHEDULE_REQUESTED_BY_PROVIDER = 'reschedule_requested_by_provider'; // requested by provider
export const RESCHEDULE_REQUEST_ACCEPTED_BY_USER = 'reschedule_request_accepted_by_user'; // accepted by user

export const NOT_AVAILABLE = 'N/A';

export const GIFT_CARD_LINK = 'gift_card_link';
export const ARTIST_LINK = 'artist_link';
export const TYPE_OFFER = 'offer';
export const PRODUCT_OFFER = 'product_offer';
export const PRODUCT_REDIRECTION_LINK = 'product_redirection_link';

export const GENERAL = 'general';
export const PRIVATE = 'private';
