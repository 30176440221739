import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ServiceCategories = lazy(() => import('../../container/gifts/index'));

const CategoriesRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ServiceCategories} />
      <Route path={`${path}/order`} component={ServiceCategories} />
      <Route path={`${path}/cards`} component={ServiceCategories} />
      <Route path={`${path}/designs`} component={ServiceCategories} />
      <Route path={`${path}/terms`} component={ServiceCategories} />
      <Route path={`${path}/resellers`} component={ServiceCategories} />
      <Route path={`${path}/codes`} component={ServiceCategories} />

    </Switch>
  );
};

export default CategoriesRoutes;
