import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Codes = lazy(() => import('../../container/discountCodes/discountCodes'));
const CodeDetailPage =lazy(() => import('../../container/discountCodes/discountCodeDetail'))
const ProviderCodeDetailPage=lazy(() => import('../../container/discountCodes/providerDiscountCodeDetail'))
const AddCoupon = lazy(()=>import('../../container/discountCodes/addCode'))
const EditProviderCoupon=lazy(()=>import('../../container/discountCodes/editCode'))
const EditPromotionalCoupon=lazy(()=>import('../../container/discountCodes/editPromotionalCode'))

const AppointmentDetailPage =lazy(() => import('../../container/orders/appointmentDetail'))
const MarketerDetailPage =lazy(() => import('../../container/users/marketer/marketerDetail'))

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Codes} />
      <Route path={`${path}/add`} component={AddCoupon} />
      <Route path={`${path}/edit/:id`} component={EditProviderCoupon} />
      <Route path={`${path}/edit-promo/:id`} component={EditPromotionalCoupon} />
      <Route path={`${path}/detail/:id`} component={CodeDetailPage} />
      <Route path={`${path}/code-detail/:id`} component={ProviderCodeDetailPage} />
      <Route path={`${path}/appointment/:id`} component={AppointmentDetailPage} />
      <Route path={`${path}/marketer-detail`} component={MarketerDetailPage} />
    </Switch>
  );
};

export default DashboardRoutes;
