import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Cities = lazy(() => import('../../container/cities/index'));
const Artists = lazy(() => import('../../container/cities/artists'));


const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Cities} />
      <Route path={`${path}/artists/:id`} component={Artists} />
    </Switch>
  );
};

export default DashboardRoutes;
