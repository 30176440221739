import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const WalletContainer = lazy(() => import('../../container/wallet/walletContainer'));


const WalletRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={WalletContainer} />
      <Route exact path={`${path}/providers`} component={WalletContainer} />
      <Route exact path={`${path}/users`} component={WalletContainer} />
      <Route exact path={`${path}/admin`} component={WalletContainer} />
      <Route exact path={`${path}/settings`} component={WalletContainer} />
    </Switch>
  );
};

export default WalletRoutes;
