import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Services = lazy(() => import('../../container/percentage/percentageContainer'));
const OrderDetailPage =lazy(() => import('../../container/orders/orderDetail'))
const AppointmentDetailPage =lazy(() => import('../../container/orders/appointmentDetail'))
const MarketerDetailPage =lazy(() => import('../../container/users/marketer/marketerDetail'))

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Services} />
      <Route exact path={`${path}/service`} component={Services} />
      <Route exact path={`${path}/product`} component={Services} />
      <Route exact path={`${path}/admin`} component={Services} />
      <Route exact path={`${path}/settings`} component={Services} />
      <Route path={`${path}/detail/:id`} component={OrderDetailPage} />
      <Route path={`${path}/appointment/:id`} component={AppointmentDetailPage} />
      <Route path={`${path}/marketer-detail`} component={MarketerDetailPage} />
    </Switch>
  );
};

export default DashboardRoutes;
