/* eslint-disable no-dupe-keys */
import { getItem } from './utility/localStorageControl';

const data = {
  resources: {
    '': ['', ''],
    ok: ['Ok', 'تمام'],
    active_appointments: ['Active Appointments', 'المواعيد النشطة'],
    past_appointments: ['Past Appointments', 'المواعيد السابقة'],
    active_orders: ['Active orders', 'الطلبات الحالية'],
    past_orders: ['Past orders', 'الطلبات الماضية'],
    client: ['Client', 'عميلة'],
    notification: ['Notification', 'تنبيه'],
    close: ['Close', 'إغلاق.'],
    mobile: ['Mobile', 'الجوال'],
    price: ['Price', 'السعر'],
    time: ['Time', 'الوقت'],
    status: ['Status', 'الحالة'],
    all: ['All', 'الكل'],
    today: ['Today', 'اليوم'],
    week: ['Week', 'اسبوع'],
    month: ['Month', 'شهر'],
    year: ['Year', 'السنة'],
    add_new: ['Add New', 'موعد جديد'],
    walk_in: ['Walk in', 'حضور في المركز'],
    services_or_products: ['Services/Products', 'الخدمات / المنتجات'],
    location: ['Location', 'الموقع'],
    your_location: ['Your location', 'موقعك'],
    note: ['Note', 'ملاحظات'],
    service_location: ['Service location', ' مكان تقديم الخدمة'],
    search: ['Search', 'بحث'],
    both: ['Both', 'كلاهما'],

    error: ['Error', 'خطأ'],
    please_select_date: ['Please select date', 'الرجاء اختيار معلومات'],
    please_select_time: ['Please select time', 'الرجاء اختيار الوقت'],
    select_atleast_one_service_or_product: [
      'Please select atleast one service or product',
      'الرجاء اختيار منتج او خدمة على الاقل',
    ],
    select_date: ['Select date', 'اختيار تاريخ'],
    start_date: ['Start date', 'تاريخ البداية'],
    end_date: ['End date', ' تاريخ النهاية'],

    // users management
    search_by_name_or_number: ['Search by name or number', 'البحث بالاسم، الرقم'],
    users: ['Users', 'المستخدمين'],
    providers: ['Providers', ' مقدمين الخدمة'],
    marketers: ['Marketers', 'المسوقين'],
    agents: ['Agents', 'الإداريين'],
    notes: ['Notes', 'ملاحظات'],
    user_detail: ['User detail', 'تفاصيل المستخدم'],
    de_activate: ['De-activate', 'إيقاف'],
    activate: ['Activate', 'تنشيط'],
    users_wallet_current_balance: ['Users current wallet balance', 'مجموع محافظ المستخدمين'],
    admin_wallet_current_balance: ['Admin current wallet balance', 'مجموع محفظة التطبيق'],

    // wallet

    received_from_user: ['Recieved from user', 'مستلمة من العميلة'],
    refunded_to_user_wallet: ['Refunded to user wallet', 'مسترجعة الى محفظة العميلة'],
    cancellation_charge: ['Cancellation charge', 'رسوم إلغاء'],
    payment_received_from_user: ['Payment recieved from user', 'إستلام المبلغ من العميلة'],
    credited_to_wallet: ['Credited to wallet', 'إضافة الى المحفظة'],
    debited_from_wallet: ['Debited from wallet', 'خصم من المحفظة'],
    transferred_to_account: ['Transferred to account', 'تحويل للحساب البنكي'],
    refund_to_account: ['Refund to account', 'استرجاع للحساب البنكي'],
    user_wallet_recharge: ['User wallet recharge', 'شحن محفظة العميلة'],
    promo_coupon_discount: ['Promo coupon discount', 'خصم كود ترويجي'],
    marketer_commission: ['Marketer commission', 'عمولة المسوق'],
    marketer_promo_code_commission: ['Marketer promo code commission', 'عمولة كود المسوق الترويجي'],
    offer_discount: ['Offer discount', 'خصم العرض'],
    gift_order_discount: ['Gift order discount', 'خصم طلبات الهدايا'],
    reseller_code_discount: ['Reseller code discount', 'خصم كود معيد البيع'],
    payfort_commission: ['Payfort commission', 'عمولة بوابة الدفع'],
    tax_for_payfort_commission: ['Tax for payfort commission', 'ضريبة على عمولة بوابة الدفع'],
    jamelah_completion_commission: ['Jamelah completion commission', 'عمولة جميلة'],
    jamelah_cancellation_commission: ['Jamelah cancellation commission', 'عمولة جميلة من الإلغاء'],
    payment_transfer_bank_fee: ['Payment transfer bank fee', 'رسوم التحويل البنكي'],
    attend: ['Attend', 'متابعة'],
    edit_offer: ['Edit offer', 'تعديل العرض'],
    edit_product: ['Edit product', 'تعديل المنتج'],
    edit_wallet: ['Edit wallet', 'تعديل المحفظة'],
    enter_the_amount: ['Enter the amount', 'ادخل المبلغ'],
    purpose: ['Purpose', 'الغرض'],
    transaction_note: ['Transaction note', 'نوع العملية'],
    payment_transfered: ['Payment Transferred', 'تم تحويل المبلغ الى بنك المستفيد'],
    refund: ['Refund', 'استرجاع'],
    credit_to_wallet: ['Credit To Wallet', 'شحن المحفظة'],
    debit_from_wallet: ['Debit from Wallet', 'خصم من المحفظة'],
    upload_image: ['Upload image', 'رفع صوره'],
    search_by_order_id_amount: ['Search by Order Id, Amount', 'بحث بواسطة رقم الطلب، المبلغ'],
    edit_gift_card: ['Edit gift card', 'تعديل'],
    edit_card_design: ['Edit card design', 'تعديل'],
    edit_article: ['Edit Article', 'تعديل'],
    request_status_updated_successfully: ['Request status updated successfully', 'تم تحديث حالة الطلب بنجاح'],
    error_in_updating_request_status: ['Error in updating request status', 'هناك مشكلة في تحديث حالة الطلب'],
    invoice: ['Invoice', 'فاتورة'],
    revoke: ['Revoke', 'إلغاء الموافقة'],
    max_free_charge_amount: ['Max free charge amount', 'الحد الاعلى للشحن الترويجي'],
    charge_percent: ['Charge percent', 'نسبة الشحن الترويجي'],
    charge_percentage_enabled: ['Charge percentage enabled', 'الشحن الترويجي مفعل'],
    from: ['From', 'من'],
    total_charge: ['Total charge', 'مجموع الشحن'],
    charge_percentage: ['Charge percentage', 'نسبة الشحن'],
    edit_wallet_charge_settings: ['Edit wallet charge settings', 'تعديل إعدادات الشحن الترويجي'],
    promotional_code: ['Promotional code', 'الكود الترويجي'],
    payfort_charge: ['Payfort charge', 'عمولة بوابة الدفع'],
    bank_transfer_fee: ['Bank transfer fee', 'عمولة تحويل البنك'],
    transaction_id: ['Transaction Id', 'رقم العملية'],
    total_amount: ['Total amount', 'المجموع الكلي'],
    discount: ['Discount', 'الخصم'],
    payfort: ['Payfort', 'بوابة الدفع'],
    provider_amount: ['Provider amount', 'مبلغ المقدم'],
    edit_review: ['Edit Review', 'تعديل التقييم'],
    text: ['Text', 'النص'],
    add_review_pic: ['Add review image', 'إضافة صورة تقييم'],
    review_images: ['Review images', 'صور التقييم'],
    payment_amount: ['Payment amount', 'المبلغ المدفوع'],
    order_invoice: ['Order invoice', 'فاتورة الطلب'],
    jamelah_invoice: ['Jamelah invoice', 'فاتورة جميلة'],
    sales_report: ['Sales report', 'تقرير المبيعات'],
    advance_payment_report: ['Advanced Payment Report', 'تقرير المبيعات الآجلة'],
    payment_portal_fee: ['Payment portal fee', 'عمولة بوابة الدفع'],
    payment_portal_fee_tax: ['Payment portal fee tax', 'ضريبة عمولة بوابة الدفع'],
    discount_by_provider: ['Discount by provider', 'خصم بواسطة المقدم'],
    discount_by_jamelah: ['Discount by Jamelah', 'خصم بواسطة جميلة'],
    sales_type: ['Sales type', 'نوع المبيعات'],
    client_name: ['Client name', 'اسم العميلة'],
    client_mobileno: ['Client number', 'رقم العميلة'],
    artist_name: ['Provider name', ' مقدم الخدمة'],
    artist_mobileno: ['Provider number', 'هاتف المقدم'],
    paid_amount: ['Paid amount', ' المبلغ المدفوع'],
    profit: ['Profit', 'الربح'],
    free_amount: ['Free extra Balance', 'الرصيد الاضافي المجاني'],
    users_joined_today: ['Users joined today', 'انضموا اليوم'],
    edit_agent: ['Edit agent', 'تعديل'],
    discount_code: ['Discount code', 'كود الخصم'],
    // orders

    all_orders: ['All orders', 'كل الطلبات'],
    service_orders: ['Service orders', 'طلبات الخدمات'],
    product_orders: ['Product orders', 'طلبات المنتجات'],
    web_services: ['Web services', 'خدمات الويب'],
    refresh_list: ['Refresh list', 'تحديث القائمة'],
    new_chat: ['New chat', 'محادثة جديدة'],
    support_chat: ['Support chat', 'رسائل الدعم'],
    offer_products: ['Offer products', 'منتجات ترويجية'],
    offer_categories: ['Offer categories', 'تصنيفات العروض'],
    name_ar: ['Arabic name', 'الاسم عربي'],
    name_en: ['English name', 'الاسم انجليزي'],
    expiry_date: ['Expiry date', 'تاريخ الانتهاء'],
    new_support_chat: ['New support chat', 'محادثة دعم جديدة'],
    new_support_message: ['New support chat', 'محادثة دعم جديدة'],
    add_offer: ['Add offer', 'إضافة عرض'],
    in_active: ['In active', 'غير نشط'],
    are_you_sure_to_delete: ['Are you sure you want to delete?', 'هل انت متأكد من الحذف؟'],
    add_offer_category: ['Add offer category', 'إضافة تصنيف'],
    add_offer_product: ['Add offer product', 'إضافة منتج ترويجي'],
    add_new_offer: ['Add new offer', 'إضافة عرض'],
    images: ['Images', 'الصور'],
    home_image_en: ['Home image - English', 'التصميم حجم صغير / English'],
    home_image_ar: ['Home image - Arabic', 'التصميم حجم صغير / عربي'],
    detail_image_en: ['Detail image - English', 'التصميم حجم كبير / English'],
    detail_image_ar: ['Detail image - Arabic', 'التصميم حجم صغير / عربي'],
    details: ['Details', 'التفاصيل'],
    customer_price: ['Customer price', 'السعر للعميل'],
    provider_price: ['Provider price', 'السعر لمقدم الخدمة'],
    max_count: ['Max count', 'العدد الاقصى'],
    cities: ['Cities', 'المدينة'],
    provider_location: ['Provider location', 'مكان المقدم'],
    user_location: ['User location', 'مكان العميله'],
    service_offer: ['Service offer', 'عرض خدمات'],
    product_offer: ['Product offer', 'عرض منتجات'],
    gift_card_added: ['Gift Card Added', 'تم إضافة كرت هدية'],
    gift_card: ['Gift Card', 'رابط للهدايا'],
    provider_link: ['Provider link', 'رابط لملف مقدم خدمة'],
    product_link: ['Product link', 'عرض منتج ترويجي'],
    show_on_map: ['Show on map', 'إظهار على الخريطة'],
    hide: ['Hide', 'إخفاء'],
    show: ['Show', 'إظهار'],
    sent_notifications: ['Sent notifications', 'الاشعارات المرسلة'],

    // categories container
    sub_categories: ['Sub categories', 'التصنيفات الفرعية'],
    main_categories: ['Main categories', 'التصنيفات الرئيسية'],
    add_sub_category: ['Add sub category', 'إضافة تصنيف فرعي'],
    add_main_category: ['Add main category', 'إضافة تصنيف رئيسي'],
    service_categories: ['Service categories', 'تصنيفات الخدمات'],
    product_categories: ['Product categories', 'تصنيفات المنتجات'],
    upload_image_en: ['Upload image - English', 'رفع صورة / English'],
    upload_image_ar: ['Upload image - Arabic', 'رفع صورة / عربي'],
    add_title: ['Add title', 'إضافة عنوان'],
    providers_wallet_current_balance: ['Providers wallet current balance', 'مجموع محافظ المقدمين'],
    wallets: ['Wallets', 'المحافظ'],
    wallet_charge_settings: ['Wallet charge settings', 'إعدادات الشحن الترويجي'],
    admin: ['Admin', 'مدير عام'],
    service_commission: ['Service commission', 'عمولة الخدمة'],
    product_commission: ['Product commission', 'عمولة المنتج'],
    default_service_commission: ['Default service commission', 'العمولة الافتراضية للخدمات'],
    default_product_commission: ['Default product commission', 'العمولة الافتراضية للمنتجات'],
    edit_service_commission: ['Edit service commission', 'تعديل عمولة الخدمات'],
    edit_product_commission: ['Edit product commission', 'تعديل عمولة المنتجات'],

    paid_payment: ['Paid Payment', 'دفع مدفوع'],
    paid_and_confirmed: ['Paid & Confirmed', 'مدفوع ومؤكد'],
    paid_confirmed: ['Paid & Confirmed', 'مدفوع ومؤكد'],
    artist_accepted_waiting_payment: ['Provider Accepted Waiting Payment', 'قبول الفنان في انتظار الدفع'],
    waiting_artist_response: ['Waiting Provider Response', 'في انتظار استجابة الفنان'],
    reschedule_requested: [
      'Reschedule requested, waiting for provider response',
      'تم طلب تغيير الموعد، بإنتظار الموافقة',
    ],
    reschedule_request_cancelled_by_provider: [
      'Reschedule request cancelled by provider',
      'ألغى المزود طلب تغيير الموعد',
    ],
    reschedule_request_accepted_by_user: ['Reschedule request accepted by user', 'العميلة قبلت إعادة الجدولة'],
    reschedule_request_rejected_by_user: ['Reschedule request rejected by user', 'العميلة رفضت إعادة الجدولة'],
    reschedule_request_accepted: [
      'Paid and confirmed and rescheduling accepted',
      'طلب مدفوع ومؤكد ومقبول الموعد الجديد',
    ],
    reschedule_request_rejected: ['Reschedule request rejected', ' طلب تغيير موعد مرفوض'],
    reschedule_request_cancelled: ['Reschedule request cancelled', 'تم إلغاء طلب إعادة الجدولة'],
    reschedule_request_expired: ['Reschedule request expired', 'طلب تغيير الموعد منتهي'],

    user_cancelled_before_artist_accept: ['User Cancelled Before Provider Accept', 'تم إلغاء المستخدم قبل قبول الفنان'],
    artist_cancelled: ['Provider Cancelled', 'فنان ملغى'],
    user_accepted_newtime: ['User Accepted New Time', 'قبل المستخدم وقت جديد'],
    user_cancelled_newtime: ['User Cancelled New Time', 'إلغاء المستخدم وقت جديد'],
    user_cancelled_payment: ['User Cancelled Payment', 'المستخدم ألغي الدفع'],
    user_cancelled_after_payment: ['User Cancelled After Payment', 'تم إلغاء المستخدم بعد الدفع'],
    artist_cancelled_after_payment: ['Provider Cancelled After Payment', 'تم إلغاء الفنان بعد الدفع'],
    order_cancelled_by_customer_service: ['Order Cancelled by Customer Service', 'تم إلغاء الطلب من قبل خدمة العملاء'],
    suggested_newtime: ['Suggested New Time', 'اقترح وقت جديد'],
    expired_no_user_resp: ['Expired No User Response', 'انتهت صلاحية أي استجابة المستخدم'],
    expired_no_artist_resp: ['Expired No Provider Response', 'انتهت صلاحية أي رد الفنان'],
    expired_no_payment: ['Expired No Payment', 'انتهت صلاحية أي دفع'],

    // offer

    please_add_all_images: ['Please add all images', 'الرجاء إضافة جميع الصور'],
    please_enter_arabic_and_english_name: ['Please enter arabic and english name', 'الرجاء إدخال الاسم بالعربي'],
    please_select_the_cities: ['Please select the cities', 'اختيار المدينة'],
    please_select_offer_category: ['Please select offer category', 'تصنيف العرض'],
    please_select_offer_product: ['Please select offer product', 'اختيار منتج ترويجي'],
    please_select_the_provider: ['Please select the provider', 'اختيار مقدم الخدمة'],
    please_enter_delivery_details: ['Please enter delivery details', 'إدخال بيانات التوصيل'],
    please_select_start_and_end_date: ['Please enter start and end date', 'مدة العرض'],
    please_enter_all_details_of_offer_service: [
      'Please enter all details of offer service',
      'لرجاء إدخال كل تفاصيل العرض',
    ],
    edit_offer_category: ['Edit offer category', 'تعديل التصنيف'],

    // gifts
    gift_cards: ['Gift cards', 'كروت الهدايا'],
    design: ['Design', 'تصميم الكرت'],
    card_designs: ['Card designs', 'تصاميم الكروت'],
    resellers: ['Resellers', 'عملاء البيع'],
    sender: ['Sender', 'المرسل'],
    reciever: ['Reciever', 'المستقبل'],
    payment_type: ['Payment type', 'نوع الدفع'],
    expiry: ['Expiry', 'تاريخ الانتهاء'],
    message: ['Message', 'الرسالة'],
    add_card: ['Add card', 'إضافة بطاقة'],
    actual_price: ['Actual price', 'السعر الفعلي'],
    add_codes: ['Add codes', 'إضافة كود'],
    reseller: ['Reseller', 'عملاء البيع'],
    reseller_percentage: ['Reseller percentage', 'نسبة عميل البيع'],
    add_resellers: ['Add resellers', 'إضافة عميل بيع'],
    add_new_gift_card: ['Add new gift card', 'إضافة بطاقة هدية'],
    charge_price: ['Charge price', 'سعر الشحن'],
    add_new_card_design: ['Add new card design', 'إضافة تصميم جديد'],
    upload_thumbnail_design: ['Upload thumbnail design', 'رفع التصميم الصغير'],
    upload_main_design: ['Upload main design', 'رفع التصميم الكبير'],
    please_enter_the_price: ['Please enter the price', 'الرجاء إدخال السعر'],
    add_new_reseller_code: ['Add new reseller code', ''],
    edit_reseller_code: ['Edit reseller code', 'تعديل كود عميل البيع'],
    isactive: ['Is active', 'نشط'],
    generate: ['Generate', 'توليد كود'],
    please_click_generate_code: ['Please click generate code', 'الرجاء الضغط لتوليد كود'],
    add_new_reseller: ['Add new reseller', 'إضافة عميل بيع جديد'],
    edit_reseller: ['Edit reseller', 'تعديل عميل البيع'],
    please_enter_the_name: ['Please enter the name', 'الرجاء دخال الاسم'],
    please_enter_the_percentage: ['Please enter the percentage', 'الرجاء إدخال النسبة'],

    // links
    dynamic_content: ['Dynamic content', 'محتوى متغير'],
    new_article: ['New article', 'مقالة جديدة'],
    app_home_title: ['App home title', 'عنوان الصفحة الرئيسية في التطبيق'],
    articles: ['Articles', 'المقالات'],
    about_us: ['About us', 'عن جميلة'],
    terms_and_conditions: ['Terms and conditions', 'الشروط والاحكام'],
    title_ar: ['Title - Arabic', 'العنوان عربي'],
    title_en: ['Title - English', 'العنوان English'],
    please_enter_body_contents: ['Please enter body contents', 'الرجاء إدخال المحتوى'],

    // notifications
    send_notifications: ['Send notifications', 'إرسال إشعار'],
    new_group_notification: ['New group notification', 'إرسال إشعار جماعي'],
    single_notifications: ['Single notifications', 'إشعار فردي'],
    body: ['Body', 'المحتوى'],
    sent_to: ['Sent to', 'تم الارسال الى'],
    linked_provider: ['Linked Provider', 'مربوطة بمقدم الخدمة'],
    linked_offer: ['Linked offer', 'مربوطة بالعرض'],
    sent_by: ['Sent by', 'تم الارسال بواسطة'],
    send_message: ['Send message', 'إرسال رسالة'],
    send_notification: ['Send notification', 'إرسال إشعار'],
    send_group_notification: ['Send group notification', 'إرسال إشعار جماعي'],
    push_notification: ['Push notification', 'الإشعارات'],
    sms: ['SMS', 'SMS'],
    link_to: ['Link to', 'ربط في'],
    offer: ['Offer', 'العرض'],
    users_by_role: ['Users by role', 'فئة المستخدمين'],

    titles: ['Titles', 'الالقاب'],
    edit_artist_title: ['Edit provider title', 'تعديل لقب'],
    add_artist_title: ['Add provider title', 'إضافة لقب'],

    // codes
    jamelah_codes: ['Jamelah codes', 'اكواد خصم جميلة'],
    provider_codes: ['Provider codes', 'اكواد خصم المقدمين'],
    new_code: ['New code', 'كود جديد'],
    applied_count: ['Applied count', 'عدد مرات الاستخدام'],
    max_discount: ['Max discount', 'حد الخصم الاعلى'],
    max_user: ['Max user', 'حد الاستخدام الاعلى'],
    max_userreached: ['Max user reached', 'تم الوصول الى حد الاستخدام الاعلى'],
    expiry_status: ['Expiry status', 'حالة الصلاحية'],
    discount_type: ['Discount type', 'نوع الخصم'],
    as_percent: ['As percent', 'نسبة'],
    as_amount: ['As amount', 'مبلغ'],
    total_usage_count: ['Total usage count', 'مجموع الاستخدام'],
    limited: ['Limited', 'محدود'],
    unlimited: ['Unlimited', 'غير محدود'],
    total_max_usage_count: ['Total max usage count', 'حد الاستخدام الكلي'],
    individual_usage_count: ['Individual usage count', 'حد الاستخدام الفردي'],
    multiple: ['Multiple', 'متعدد'],
    once: ['Once', 'مره واحدة'],
    please_select_atleast_one_provider: ['Please select atleast one provider', 'الرجاء اختيار مقدم خدمة واحد عالاقل'],
    discount_percent_should_be_greater_than_zero: [
      'Discount percent should be greater than zero',
      'نسبة الخصم يجب ان تكون اعلى من صفر',
    ],
    please_enter_code_name: ['Please enter the code name', 'الرجاء إدخال اسم الكود'],
    edit_discount_code: ['Edit discount code', 'تعديل كود الخصم'],

    // category
    add_product_category: ['Add product category', 'اضافة تصنيف'],
    edit_main_category: ['Edit main category', 'تعديل التصنيف الرئيسي'],
    sub_category: ['Sub category', 'التصنيف الفرعي'],
    edit_product_category: ['Edit product category', 'تعديل تصنيف المنتج'],
    pinned: ['Pinned', 'مثبت'],
    rating: ['Rating', 'التقييم'],
    top_providers_listing: ['Top providers listing', 'المقدمين المثبتين'],
    listed_providers: ['Listed providers', 'المقدمين المثبتين'],
    unlisted_providers: ['UnListed providers', 'المقدمين الغير مثبتين'],

    // main_service

    add_new_main_service: ['Add new main service', 'إضافة خدمة جديدة'],
    edit_main_service: ['Edit main service', 'تعديل الخدمة'],
    update: ['Update', 'تحديث'],
    monthly_average_price: ['Monthly average price', 'معدل سعر الطلب الشهري'],

    product_requests: ['Product requests', 'طلبات المنتجات'],
    total_income: ['Total income', 'الدخل الكلي'],
    attachments: ['Attachments', 'المرفقات'],
    sms_notification: ['SMS notification', 'تنبيهات SMS'],
    percentage_on_services: ['Percentage on services', 'النسبة على الخدمات'],
    percentage_on_products: ['Percentage on products', 'النسبة على المنتجات'],
    minimum_price: ['Minimum price', 'ادنى سعر'],
    delivery_note: ['Delivery note', 'ملاحظات التوصيل'],
    service_delivery_fee: ['Service delivery fee', 'سعر توصيل الخدمات'],
    product_deliery_charge: ['Product delivery charge', 'سعر توصيل المنتجات'],
    product_deliery_range: ['Product delivery range', 'نطاق توصيل المنتجات'],
    product_deliery_time: ['Product delivery time', 'وقت التوصيل'],
    id_iquama_number: ['ID/Iquama number', 'رقم الهوية / الاقامة'],
    bank_account_holder: ['Bank account holder', 'اسم صاحب الحساب'],
    iban: ['IBAN', 'الآيبان'],
    invoice_settings: ['Invoice settings', 'إعدادات الفوترة'],
    stock: ['Stock', 'المخزون'],
    sell_in_app: ['Sell in app', 'متاح للبيع في التطبيق'],
    upload: ['Upload', 'تحميل'],

    other_settings: ['Other settings', 'إعدادات اخرى'],
    profile_visiblity: ['Profile visiblity', 'ظهور البروفايل'],
    profile_appears_in_jamelah_app: ['Profile appears in Jamelah app', 'ظهور البروفايل في التطبيق'],
    profile_visible_status_admin_control: ['Profile visible status admin control', 'تحكم الاداره في ظهور البروفايل'],
    delivery_fee_optional: ['Delivery fee (optional)', 'ريوم التوصيل (اختياري)'],
    add_notes_about_the_user: ['Add notes about the user', 'إضافة ملاحظات عن المستخدم'],
    services_delivery: ['Services delivery', 'توصيل الخدمات'],
    creation_date: ['Creation date', 'وقت الإنشاء'],
    order_target: ['Order target', 'الطلبات المستهدفة'],
    marketer_information: ['Marketer information', 'معلومات المسوق'],
    marketer_name: ['Marketer name', 'اسم المسوق'],
    bank_information: ['Bank information', 'معلومات البنك'],
    code_to_invite_providers: ['Code to invite providers', 'كود التسجيل للمقدمين'],
    commission_amount: ['Commission amount', 'مبلغ العمولة'],
    target_orders: ['Target orders', 'الطلبات المستهدفة'],
    add_new_marketer: ['Add new marketer', 'إضافة مسوق جديد'],
    has_access_to_everything: ['Has access to everything', 'لديه صلاحيات الوصول لكل شي'],
    cant_access_dashboard: ['Cant access dashboard', 'لا يستطيع مشاهدة لوحة التحكم'],
    cant_access_wallet: ['Cant access wallet', 'لا يستطيع الوصول الى المحافظ'],
    rules: ['Rules', 'القواعد'],
    add_new_agent: ['Add new agent', 'إضافة عميل جديد'],
    agent_information: ['Agent information', 'معلومات العميل'],
    agent_name: ['Agent name', 'اسم العميل'],
    account_information: ['Account information', 'معلومات الحساب'],
    password: ['Password', 'كلمة المرور'],

    // sales container

    cash_movement_summary: ['Cash movement summary', 'ملخص تحركات الكاش'],
    collected: ['Collected', 'مُحصل'],
    refund_paid: ['Refund paid', 'مسترجع مدفوع'],
    item_type: ['Item type', 'النوع'],
    sales_quantity: ['Sales quantity', 'تم بيعه'],
    refund_amount: ['Refund amount', 'مبلغ المسترجع'],
    are_you_sure_to_refund_the_amount_of: ['Are you sure to refund the amount of', 'هل انتي متأكدة من استرجاع مبلغ'],
    refunded_by_cash: ['Refunded with cash', 'استرجاع كاش'],
    refunded_by_card: ['Refunded by card', 'استرجاع للبطاقة'],
    cash: ['Cash', 'كاش'],
    credit_card: ['Credit card', 'بطاقة إئتمانية'],
    jamelah: ['Jamelah', 'جميلة'],

    // marketer dashboard

    orders_total_amount: ['Orders total amount', 'مجموع مبلغ الطلبات'],
    added_date: ['Added date', 'تاريخ الإضافة'],
    add_marketer: ['Add marketer', 'المسوق'],
    add_agent: ['Add agent', 'إضافة إداري'],
    user_name: ['User name', 'اسم المستخدم'],
    support_messages: ['Support messages', 'رسائل الدعم'],
    saved: ['Saved', 'تم الحفظ'],
    marketer_saved: ['Marketer saved', 'تم اضافة المسوق بنجاح'],

    service_providers: ['Service providers', 'مقدمين الخدمة'],
    referral_code: ['Referral code', 'كود الدعوة'],
    optional: ['Optional', 'اختياري'],
    in_this_page_you_will: [
      ' In this page you will find all providers who joined using your invitation code',
      'هنا قائمة مقدمين الخدمة المنضمين باستخدام الكود الخاص بك',
    ],
    completed_target_orders: ['Completed target Orders', 'اكتمال الطلبات المستهدفة'],
    your_code: ['Your code', 'كودك'],
    commission_price: ['Commission price', 'سعر العمولة'],
    order_target_for_provider: ['Order target for provider', 'الطلبات المكتملة المستهدفة للمقدم'],
    your_total_commission: ['Your total commission', 'مجموع عمولتك'],
    providers_completed_orders: ['Providers completed orders', 'مقدمين اكملوا الطلبات المستهدفة'],
    providers_pending_orders: ['Providers pending orders', 'مقدمين لم يكملو الطلبات المستهدفة'],

    dashboard: ['Dashboard', 'الرئيسية'],
    codes: ['Codes', 'الاكواد'],

    total_code_sales: ['Total code sales', 'مجموع مبيعات اكوادك'],
    total_commission: ['Total commission', 'مجموع عمولتك'],
    completed_orders: ['Completed order', 'الطلبات المكتملة'],
    top_coupons: ['Top coupons', 'اعلى الاكواد استخداماً'],

    marketer: ['Marketer', 'مسوق'],

    completed_only: ['Completed only', 'المكتملة فقط'],
    orderId: ['Order Id', 'رقم الطلب'],
    usage_times: ['Usage times', 'عدد مرات الاستخدام'],
    your_codes: ['Your codes', 'اكوادك'],

    created_time: ['Created time', 'وقت الإنشاء'],

    valid: ['Valid', 'نشط '],
    expired: ['Expired', 'منتهي'],
    sorry_your_number_is_not_registered: ['Sorry , Your number is not registered', 'عفواً، الرقم المدخل غير مسجل'],
    verification_code_is_incorrect: ['Verification code is incorrect', 'كود التحقق المدخل غير صحيح'],
    // dashboard container

    total_sales: ['Total sales', 'مجموع المبيعات'],
    app_service_orders: ['App service orders', 'طلبات التطبيق (خدمات)'],
    app_product_orders: ['App product orders', 'طلبات التطبيق (منتجات)'],
    since_last_month: ['Since last month', 'عن الشهر الماضي'],
    detailed_sales: ['Detailed sales', 'تفاصيل المبيعات'],
    jamelah_services: ['Jamelah services', 'الخدمات بالتطبيق'],
    jamelah_products: ['Jamelah products', 'المنتجات بالتطبيق'],

    live_providers: ['Live providers', 'المقدمين النشطين'],
    gift_orders: ['Gift orders', 'طلبات الهدايا'],
    total_orders: ['Total orders', 'كل الطلبات'],
    accepted: ['Accepted', 'مقبوله'],
    paid: ['Paid', 'مدفوعه'],
    most_expiring_providers: ['Most expiring providers', 'طلبات بدون رد'],
    most_cancelling_providers: ['Most cancelling providers', 'اكثر المقدمين إلغاء'],
    most_cancelling_users: ['Most cancelling users', 'اكثر المستخدمين إلغاء'],
    top_ordered_providers: ['Top ordered providers', 'المقدمين الاكثر طلباً'],
    top_ordered_products: ['Top ordered products', 'المنتجات الاكثر طلباً'],
    top_ordered_services: ['Top ordered services', 'الخدمات الاكثر طلباً'],
    top_ordering_users: ['Top ordering users', 'المستخدمين الاكثر طلباً'],
    orders_average_price: ['Orders average price', 'معدل سعر الطلبات'],
    top_discount_codes: ['Top discount codes', 'اعلى الاكواد استخداماً'],
    export: ['Export', 'تصدير'],
    revenue: ['Revenue', 'الايرادات'],
    just_joined: ['Just joined', 'المنضمين حديثاً'],
    join_date: ['Join date', 'تاريخ الانضمام'],
    waiting_approval: ['Waiting approval', 'بانتظار الموافقة'],
    live_orders: ['Live orders', 'الطلبات النشطة'],
    waiting_for_provider_response: ['Waiting for provider response', 'بانتظار رد مقدمة الخدمة'],
    waiting_for_payment: ['Waiting for payment', 'بإنتظار الدفع'],
    suggested_new_time: ['Suggested new time', 'وقت مقترح'],
    in_progress: ['In progress', 'قيد التقديم'],
    appointment_time: ['Appointment time', 'وقت الموعد'],
    created_at: ['Created at', 'وفت الإنشاء'],
    agent: ['Agent', 'إداري'],
    approval: ['Approval', 'حالة الموافقة'],
    approve: ['Approve', 'قبول'],

    jan: ['Jan', 'يناير'],
    feb: ['Feb', 'فبراير'],
    mar: ['Mar', 'مارس'],
    apr: ['Apr', 'أبريل'],
    may: ['May', 'مايو'],
    jun: ['Jun', 'يونيو'],
    jul: ['Jul', 'يوليو'],
    aug: ['Aug', 'أغسطس'],
    sep: ['Sep', 'سبتمبر'],
    oct: ['Oct', 'أكتوبر'],
    nov: ['Nov', 'نوفمبر'],
    dec: ['Dec', 'ديسمبر'],

    top_employees: ['Top employees', 'افضل الموظفين'],
    orders: ['Orders', 'الطلبات'],
    amount: ['Amount', 'المبلغ'],
    artistemployee: ['Artist Employee', 'عاملة'],

    top_clients: ['Top clients', 'افضل العملاء'],
    top_services: ['Top services', 'اكثر الخدمات مبيعاً'],
    items: ['Items', 'العناصر'],
    sar: ['SAR', 'ريال'],

    accept: ['Accept', 'قبول'],
    reject: ['Reject', 'رفض'],
    suggest_new_time: ['Suggest New Time', 'إقتراح وقت آخر'],
    order_details: ['Order details', ' تفاصيل الطلب'],
    print: ['Print', 'طباعة'],
    service_details: ['Service details', 'تفاصيل الخدمة'],
    description: ['Description', 'الوصف'],
    count: ['Count', 'العدد'],
    sub_total: ['Sub total', 'المجموع'],
    location_fees: ['Services delivery fee', 'رسوم توصيل خدمات'],
    date_and_time: ['Date and time', 'التاريخ والوقت'],
    details: ['Details', 'التفاصيل'],
    order_status: ['Order Status', 'حالة الطلب'],
    map: ['Map', 'الخريطة'],

    // settigs container
    edit_settings: ['Edit settings', ' تعديل الإعدادات'],
    app_settings: ['App settings', 'إعدادات التطبيق'],
    web_settings: ['Web settings', ' إعدادات الويب'],
    inactive: ['Inactive', 'غير نشط'],
    your_profile_doesnot_appears_in_jamelah_app: [
      'your profile doesnot appears in jamelah app',
      'ملفك لايظهر للمستخدمين في التطبيق',
    ],
    your_profile_appears_in_jamelah_app: ['your profile appears in jamelah app', 'ملفك نشط وظاهر للمستخدمين بالتطبيق'],
    info_h: ['INFO', 'التفاصيل'],
    email: ['Email', 'الإيميل'],
    city: ['City', 'المدينة'],
    financial_h: ['FINANCIAL', 'مالي'],
    financial: ['Financial', 'مالي'],
    minimum_price_per_request_h: ['MINIMUM PRICE PER REQUEST', 'الحد الادنى للطلب (بالريال)'],
    minimum_price_per_request: ['Minimum price per request', 'الحد الادنى للطلب (بالريال)'],
    bank_name: ['Bank name', 'البنك'],
    bank_account_holder_name: ['Bank account holder name', 'الحساب البنكي مسجل بأسم'],
    bank_account_number: ['Bank accont number', 'رقم الحساب البنكي (آيبان)'],
    delivery_h: ['DELIVERY', 'التوصيل'],
    delivery: ['Delivery', 'التوصيل'],
    delivery_fee: ['Delivery fee', 'سعر التوصيل (اختياري)'],
    sms_h: ['SMS', 'الرسائل'],
    sms_notifications: ['SMS notifications', 'إشعارات SMS'],
    social_h: ['SOCIAL', 'الشبكات الاجتماعية'],
    social: ['Social', 'الشبكات الاجتماعية'],
    instagram: ['Instagram', 'إنستقرام'],
    snap_chat: ['Snap chat', 'سناب شات'],
    location_h: ['LOCATION', 'الموقع'],
    will_show_in_your_invoices: ['Will show in your invoices', 'سوف يظهر في فواتيرك'],
    logo: ['Logo', 'الشعار'],
    cr_number: ['CR number', 'رقم السجل التجاري'],
    phone: ['Phone', 'الهاتف'],
    change_profile_image: ['Change profile photo', 'تغيير صوره ملفك'],
    change_logo_image: ['Change logo image', 'تغيير الشعار'],
    title: ['Title', 'اللقب'],
    if_entered_it_will_show_in_invoices: ['If entered it will show in invoices', 'سوف يظهر في الفواتير'],
    instagram_account: ['Instagram account', 'إنستقرام'],
    snapchat_account: ['Snap chat account', 'سناب شات'],
    bank: ['Bank', 'البنك'],
    additional_number: ['Additional number', 'الرقم الاضافي'],
    change_location: ['Change location', 'تغيير الموقع!'],
    profile_activeness_edited_successfully: ['Profile activeness edited successfully', 'تم تعديل ظهورك في التطبيق'],
    sms_setting_edited_successfully: ['Sms setting edited successfully', 'تم تعديل إعدادات ابرسائل بنجاح'],
    company_name_ar: ['Company name (Arabic)', 'اسم الشركة / المؤسسة  (عربي)'],
    company_name_en: ['Company name (English)', 'اسم الشركة / المؤسسة  (انجليزي)'],
    enter_your_company_name_ar: ['Enter your official company name in Arabic', 'الرجاء إدخال الاسم الرسمي بالعربي'],
    enter_your_company_name_en: ['Enter your official company name in English', 'الرجاء إدخال الاسم الرسمي بالانجليزي'],
    enter_mobilenumber: ['Enter phone number', 'الرجاء إدخال الهاتف هنا'],
    enter_your_email: ['Enter email', 'الرجاء إدخال الايميل الرسمي هنا'],
    enter_your_building_number: ['Enter your building number', 'الرجاء إدخال رقم المبنى'],
    enter_your_street_name: ['Enter your street name', 'الرجاء إدخال اسم الشارع'],
    enter_your_neighbourhood: ['Enter your neighbourhood', 'الرجاء إدخال الحي'],
    enter_your_postal_code: ['Enter your postal code', 'الرجاء إدخال الرمز البريدي'],
    enter_your_city: ['Enter your city', 'الرجاء إدخال المدينة'],
    address: ['Address', 'العنوان'],
    to_be_shown_in_your_invoices: ['to be shown in your invoices', 'العنوان سيظهر في الفواتير فقط'],
    building_number: ['Building number', 'رقم المبنى'],
    street_name: ['Street name', 'اسم الشارع'],
    neighbourhood: ['Neighbourhood', 'الحي'],
    postal_code: ['Postal code', 'الرمز البريدي'],

    // stock order and supplier

    order_no: ['Order No', 'رقم الطلب'],
    ordered: ['Ordered', 'تم الطلب'],
    service_count: ['Service count', 'عدد الخدمات'],
    new_stock_orders: ['New stock orders', 'امر شراء جديد'],
    please_select_supplier: ['Please select supplier', 'الرجاء إختيار مورد'],
    please_select_product: ['Please select product', 'الرجا إختيار منتج'],
    please_enter_supplier_price: ['Please enter supplier price', 'الرجاء إضافة السعر'],
    select_atleast_one_product: ['Please select atleast one product', 'الرجاء اختيار منتج واحد على الاقل'],
    confirm: ['Confirm', 'تأكيد'],
    total_price: ['Total price', 'المجموع'],
    recieved_quantity: ['Recieved quantity', 'الكمية المستلمة'],
    ordered_quantity: ['Ordered quantity', ' الكمية المطلوبة'],
    supplier_optional: ['Supplier (Optional)', 'المورد (اختياري)'],
    add_supplier: ['Add Supplier', 'إضافة مورد'],
    supplier_price_optional: ['Supplier price (Optional)', 'سعر المورد (اختياري)'],

    inventory: ['Inventory', 'المخزون'],
    suppliers: ['Suppliers', 'الموردين'],
    stock_orders: ['Stock orders', 'اوامر الشراء'],
    supplier: ['Supplier', 'المورد'],
    total_cost: ['Total cost', ' التكلفة الكلية'],
    created_date: ['Created date', 'تاريخ الإنشاء'],
    updated_at: ['Updated at', 'تم تحديثه في'],
    add_new_supplier: ['Add new supplier', 'إضافة مورد جديد'],
    supplier_information: ['Supplier information', 'معلومات المورد'],
    supplier_address: ['Supplier address', 'عنوان المورد'],
    supplier_name: ['Supplier name', 'اسم المورد'],
    contact_name: ['Contact name', 'اسم نقطة الاتصال'],
    website: ['Website', 'الموقع الالكتروني'],
    edit_supplier: ['Edit supplier', 'تعديل المورد'],
    create_new_order: ['Create new order', 'إنشاء طلب شراء جديد'],
    new_stock_order: ['New stock order', 'طلب شراء جديد'],
    to_be_delivered_on: ['To be delivered on', 'للتوصيل في'],
    recieved: ['Recieved', 'تم الاستلام'],
    stock_order_details: ['Stock order details', 'تفاصيل طلب الشراء'],
    not_redeemed: ['Not redeemed', 'لم تستخدم بعد'],
    all_status: ['All status', 'الكل'],
    redeemed: ['Redeemed', 'تم الاستعمال'],
    recieve_order: ['Recieve order', 'إستلام الطلب'],
    cancel_order: ['Cancel order', 'إلغاء الطلب'],
    supplier_price: ['Supplier price', 'سعر المورد'],
    are_you_sure_that_you_have_recieved_this_order: [
      'Are you sure that you have recieved this order?',
      'هل انتي متأكدة من استلام الطلب بالكمية المدخلة؟',
    ],
    delivery_location: ['Delivery location', 'مكان التوصيل'],
    another_location: ['Another location', 'موقع آخر'],
    my_location: ['My location', 'موقعي'],
    are_you_sure_to_approve: ['Are you sure to approve?', 'هل انت متأكد من الموافقة؟'],
    desc: ['Desc', 'الوصف'],
    delivery_date: ['Delivery date', 'تاريخ التوصيل'],
    please_enter_arabic_name_of_service: ['Please enter Arabic name of service', 'الرجاء إدخال اسم الخدمة بالعربي'],
    please_enter_english_name_of_service: [
      'Please enter English name of service',
      'الرجاء إدخال اسم الخدمة بالانجليزي',
    ],
    enter_your_name: ['Enter your name', 'ادخل اسمك'],
    enter_your_password: ['Enter your password', 'ادخل الباسوورد'],
    submit: ['Submit', 'إرسال'],
    // reports container

    jamelah_completed_orders: ['Jamelah completed orders', 'طلبات التطبيق المكتملة'],
    jamelah_paid_and_confirmed_orders: ['Jamelah paid and confirmed orders', 'طلبات التطبيق المدفوعة والمؤكدة'],
    completed_appointments: ['Completed appointments', 'المواعيد المكتملة'],
    paid_appointments: ['Paid appointments', ' المواعيد المدفوعة'],
    completed_appointments_report: ['Completed appointments report', 'المواعيد المكتملة'],
    paid_appointments_report: ['Paid appointments report', 'المواعيد المدفوعة'],
    jamelah_completed_orders_report: ['Jamelah completed orders report', 'تقرير طلبات التطبيق المكتملة'],
    jamelah_paid_and_confirmed_orders_report: [
      'Jamelah paid and confirmed orders report',
      'تقرير طلبات التطبيق المدفوعة والمؤكدة',
    ],
    send_by_email: ['Send by Email', 'إرسال بواسطة الإيميل'],
    export_excel: ['Export excel', 'تصدير اكسل'],
    export_pdf: ['Export PDF', 'تصدير PDF'],
    custom: ['Custom', 'مخصص'],
    order_id: ['Order ID', 'رقم الطلب'],
    share_by_email: ['Share by email', ' إرسال للإيميل'],
    choose_file_format: ['Choose file format', 'الرجاء اختيار صيغة الملف'],
    enter_email_address: ['Enter email address', 'ايميلك هنا'],
    send: ['Send', 'إرسال'],

    // reviews container
    service_reviews: ['Service reviews', 'تقييمات الخدمات'],
    product_reviews: ['Product reviews', ' تقييمات المنتجات'],
    check_product: ['Check product', 'الذهاب للمنتج'],
    reply: ['Reply', 'إرسال الرد'],
    reply_to: ['Reply to', 'الرد على'],

    // discount code translations
    all_discount_codes: ['All discount codes', 'كل اكواد الخصم'],
    service_discount_codes: ['Service discount codes', 'اكواد خصم الخدمات'],
    product_discount_codes: ['Product discount codes', 'اكواد خصم المنتجات'],
    before_discount: ['Before discount', 'قبل الخصم'],
    after_discount: ['After discount', 'بعد الخصم'],
    discount_amount: ['Discount amount', 'الخصم'],
    code: ['Code', 'الكود'],
    type: ['Type', 'النوع'],
    percentage: ['Percentage', 'نسبة العمولة'],
    max_usage: ['Max usage', 'العدد الاقصى لاستخدام الكود'],
    used_times: ['Used times', 'عدد مرات الاستخدام'],
    applies_on: ['Applies on', 'ينطبق على'],
    expiration_time: ['Expiration time', 'تاريخ الإنتهاء'],
    general: ['General', 'عام'],
    private: ['Private', 'خاص'],
    product: ['Product', 'المنتجات'],
    download: ['Download', 'تحميل'],
    product_d: ['Product', 'منتج'],
    code_is_added: ['Code is Added', 'تم إضافة الكود'],

    add_new_discount_code: ['Add new discount code', 'إضافة كود خصم جديد'],
    code_information: ['Code information', 'معلومات الكود'],
    code_type: ['Code type', 'نوع الكود'],
    will_appear_in_your_profile_for_everyone: [
      'Will appear in your profile for every one',
      'سوف تكون ظاهرة على ملفك في التطبيق ويمكن لأي عميلة الاستفادة منها',
    ],
    will_not_appear_in_your_profile: [
      'Will not appear in your profile , you can share with your favourite clients',
      'لن تظهر على ملفك، يمكنك مشاركتها بنفسك مع عملائك المفضلين',
    ],
    applicable_on: ['Applicable on', 'ينطبق على'],
    service_only: ['Service only', 'الخدمات فقط'],
    product_only: ['Product only', 'المنتجات فقط'],
    code_name: ['Code name', 'الكود'],
    add_new_discount: ['Add new', 'إضافة كود'],

    discount_percentage: ['Discount percentage', 'نسبة الخصم'],
    usage_count: ['Usage count', 'العدد الاقصى لاستخدام الكود'],
    discount_details: ['Discount details', 'تفاصيل الخصم'],
    discount_detail: ['Discount detail', 'تفاصيل الخصم'],
    code_used_orders: ['Code used orders', 'طلبات استُخدم فيها الكود'],
    are_you_sure_to_delete_this_discount_code: [
      'Are you sure to delete this discount code ?',
      'هل انتي متأكدة من حذف كود الخصم؟',
    ],
    // top bar dropdown
    signout: ['Sign out', 'تسجيل خروج'],
    english: ['English', 'English'],
    arabic: ['عربي', 'عربي'],
    see_all_notifications: ['See all notifications', 'الاطلاع على كل الاشعارات'],

    // notifications container
    notifications: ['Notifications', 'إشعارات'],

    // working hours container
    workinghours_note: [
      'These are the working hours for you in Jamelah app.Note that users cannot book you out of these times in Jamelah app',
      'هذه اوقات العمل الخاصة بكم للحجوزات عبر تطبيق جميلة، الرجاء ملاحظة انه لايمكن للمستخدمين حجز موعد لديكم خارج هذه الاوقات.',
    ],

    edit_working_hours: ['Edit working hours', 'تعديل ساعات العمل'],
    choose_your_working_hours: ['Choose your working hours', 'الرجاء تحديد ساعات العمل'],

    // calendar container
    am: ['AM', 'صباحاً'],
    pm: ['PM', 'مساءً'],
    day: ['Day', 'اليوم'],
    schedule: ['Schedule', 'الجدول'],
    sunday: ['Sunday', 'الأحد'],
    monday: ['Monday', 'الإثنين'],
    tuesday: ['Tuesday', 'الثلاثاء'],
    wednesday: ['Wednesday', 'الاربعاء'],
    thursday: ['Thursday', 'الخميس'],
    friday: ['Friday', 'الجمعة'],
    saturday: ['Saturday', 'السبت'],
    january: ['January', 'يناير'],
    february: ['February', 'فبراير'],
    march: ['March', 'مارس'],
    april: ['April', 'ابريل'],
    // may: ['May', 'مايو'],
    june: ['June', 'يونيو'],
    july: ['July', 'يوليو'],
    august: ['August', 'اغسطس'],
    september: ['September', 'سبتمبر'],
    october: ['October', 'اكتوبر'],
    november: ['November', 'نوفمبر'],
    december: ['December', 'ديسمبر'],
    jamelah_order_details: ['Jamelah order details', 'تفاصيل طلب جميلة'],
    at_user_location: ['At user location', ' في مكان العميلة'],
    not_selected: ['Not selected', ' غير مختارة'],
    more_details: ['More details', 'عرض كل التفاصيل'],
    min: ['min', 'دقيقة'],
    service_and_product: ['Service and product', 'خدمات ومنتجات'],
    // photos
    here_you_can_upload_work_photos: [
      'here you can upload photos of your work to show them in your profile in the app',
      'هنا يمكنك رفع صور اعمالك حيث ستظهر في ملفك في التطبيق',
    ],
    work_photos: ['Work photos', 'صور اعمالك'],
    profile_photo: ['Profile photo', 'صورك الملف'],
    change_profile_photo: [
      'here you can change the main profile photo of the app. It could be your logo or any photo of your work',
      'من هنا يمكنك رفع وتغيير صورة ملفك الرئيسية، يمكنك رفع صوره من اعمالك او شعارك',
    ],
    add_new_photos: ['Add new', 'إضافة صور'],
    change_photo: ['Change photo', 'تغيير الصورة'],
    // add service

    please_select_service_category: ['Please select service category', 'الرجاء اختيار تصنيف'],
    please_select_service: ['Please select service', 'الرجاء إختيار خدمة'],
    // please_enter_the_price: ['Please enter the price', 'ادخلي السعر'],
    please_enter_the_duration_in_minutes: ['Please enter the duration in minutes', 'مدة التقديم (بالدقائق)'],
    please_enter_the_maximum_count_per_session: [
      'Please enter maximum count per session',
      'العدد الاقصى بالطلب الواحد',
    ],
    please_select_free_service_category: ['Please select free service category', 'اختاري تصنيف الخدمة'],
    please_select_free_service: ['Please select free service', 'اختيار الخدمة المجانية'],
    add_new_service: ['Add new service', ' إضافة خدمة'],
    category: ['Category', 'التصنيف'],
    service: ['Service', 'خدمة'],
    count_per_session: ['Count per session', 'العدد الاقصى للطلب الواحد'],
    duration_in_min: ['duration (minutes)', 'مدة التقديم (بالدقائق)'],
    service_description: ['Service description', 'وصف الخدمة'],
    upload_service_photo: ['Upload service photo', 'رفع صوره للخدمة'],
    add_free_service: ['Add free service with this service (optional)', 'إضافة خدمة مجانية مع هذه الخدمة (إختياري)'],
    select_location_options: ['Service location options', 'الرجاء إختيار مكان الخدمة'],
    service_information: ['Service infromation', 'معلومات الخدمة'],

    // service container

    approved: ['Approved', 'موافق عليه'],
    pending: ['Pending', ' بإنتظار الموافقة'],
    rejected: ['Rejected', 'مرفوض'],
    revoked: ['Revoked', 'إلغاء - فسخ'],

    // Edit service
    edit_service: ['Edit Service', 'تعديل الخدمة'],

    // products container
    filters: ['Filters', 'فلاتر'],
    price_range: ['Price Range', 'حدود السعر'],
    ratings: ['Ratings', 'المراجعات'],
    search_by_name: ['Search by name', 'بحث بالاسم'],
    showing: ['Showing', 'إظهار'],
    of: ['of', 'من'],
    top_rated: ['Top Rated', ' الاعلى تقييماً'],
    newest: ['Newest', 'الاحدث'],
    data_not_found: ['No products matching', 'لا يوجد منتجات بالتصنيف المختار'],
    in_stock: ['in stock', 'في المخزن'],
    edit: ['Edit', 'تعديل'],
    and_up: ['and up', 'وأكثر'],
    search_by_mobile: ['Search by mobile', 'البحث برقم الجوال'],

    // product detail page
    available_quantity: ['Available quantity', 'الكمية المتاحة'],
    sold_quantity: ['Sold quantity', 'الكمية المباعة'],
    options: ['Options', 'الخيارات'],
    are_you_sure_to_delete_this_product: ['Are you sure to delete this product ?', 'هل انتي متأكدة من حذف هذا المنتج؟'],
    are_you_sure_to_delete_this_service: ['Are you sure to delete this service ?', 'هل انتي متأكدة من حذف هذه الخدمة؟'],
    are_you_sure_to_delete_this_client: ['Are you sure to delete this client ?', 'هل انتي متأكدة من حذف هذا العميل؟'],
    are_you_sure_to_delete_this_offday: ['Are you sure to delete this off day ?', 'هل انتي متأكدة من حذف هذه الفترة؟'],
    are_you_sure_to_delete_this_photo: ['Are you sure to delete this photo ?', 'هل انتي متأكدة من حذف هذه الصورة؟'],
    are_you_sure_to_delete_this_employee: ['Are you sure to delete this employee ?', 'هل انتي متأكدة من الحذف؟'],
    are_you_sure_to_assign_the_order_to: ['Are you sure to assign the order to ', 'هل انتي متأكدة من تعيين الطلب لـ'],
    are_you_sure_to: ['Are you sure to', 'هل انتي متأكدة من'],
    this_order: ['this order', 'هذا الطلب'],
    yes: ['Yes', 'نعم'],
    no: ['No', 'لا'],
    add_stock: ['Add stock', 'إضافة كمية'],
    remove_stock: ['Remove stock', 'إزالة كمية'],

    instock: ['in stock', 'في المخزون'],
    decrease_stock: ['Decrease stock', 'إزالة مخزون'],
    increase_stock: ['Increase stock', 'زيادة المخزون'],
    increase_reason: ['Increase reason', 'سبب الزيادة'],
    decrease_reason: ['Decrease reason', 'سبب الإزالة'],
    details_and_reviews: ['Details and reviews', 'التفاصيل والمراجعات'],
    stock_history: ['Stock history', 'تغيرات المخزون'],
    newstock: ['New stock', 'كمية جديدة'],
    return: ['Return', 'إسترجاع'],
    adjustment: ['Adjustment', 'تعديل'],
    other: ['Other', 'اسباب اخرى'],

    internaluse: ['Internal use', 'إستخدام داخلي'],
    damaged: ['Damaged', 'بضاعة متضررة'],
    // expired: ['Expired', 'منتهية الصلاحية'],
    lost: ['Lost', 'مفقودة'],

    please_select_the_reason: ['Please select the reason', 'الرجاء اختيار السبب'],
    please_select_quantity_greater_than_zero: [
      'Please select quantity greater than zero',
      'الرجاء اختيار كمية اكثر من صفر',
    ],
    quantity_adjusted: ['Quantity adjusted', 'الكمية المعدلة'],
    stock_after_adjustment: ['Stock after adjustment', 'المخزون بعد التعديل'],
    reason: ['Reason', 'السبب '],
    cost_price: ['Cost price', 'سعر التكلفة'],
    user: ['User', 'الموظف'],

    // add product
    add_new_product: ['Add new product', 'إضافة منتج'],
    please_select_product_category: ['Please select product category', 'الرجاء إختيار تصنيف'],
    please_enter_the_arabic_name: ['Please enter the arabic name', 'اسم المنتج بالعربي'],
    please_enter_the_english_name: ['Please enter the english name', 'اسم المنتج بالانجليزي'],
    please_select_product_image: ['Please select product image', 'الرجاء رفع صورة المنتج'],
    please_enter_details_for_atleat_one_product_option: [
      'Please enter details of atleast one product option',
      'الرجاء إدخال تفاصيل خيار واحد للمنتح',
    ],
    please_enter_arabic_name_for_all_options: [
      'Please enter arabic name of all options',
      'الرجاء كتابة الاسم بالعربي لكل الخيارات',
    ],
    please_enter_english_name_for_all_options: [
      'Please enter english name of all options',
      'الرجاء كتابة الاسم بالانجليزي لكل الخيارات',
    ],
    please_enter_quantity_for_all_options: ['Please enter quantity for all options', 'الرجاء إدخال الكمية لكل خيار'],
    please_enter_price_for_all_options: ['Please enter price of for options', 'الرجاء إدخال سعر لكل الخيارات'],
    product_information: ['Product Information', 'معلومات المنتج'],
    product_name_arabic: ['Product name arabic', 'اسم المنتج بالعربي'],
    product_name_english: ['Product name english', 'اسم المنتج بالانجليزي'],
    product_description: ['Product description', 'وصف المنتج'],
    available_to_sell_in_app: ['Available to sell in app', 'متاح للبيع في التطبيق'],
    available_to_get_in_app: ['Available to get in app', 'متاح للبيع بالتطبيق'],
    not_available_to_get_in_app: ['Not available to get in app', 'غير متاح للبيع في التطبيق'],
    product_options: ['Product options', 'خيارات المنتج'],
    product_option: ['Product option', 'خيار المنتج'],
    product_option_name_arabic: ['Product option name arabic', 'اسم الخيار بالعربي'],
    product_option_name_english: ['Product option name english', 'اسم الخيار بالانجليزي'],
    sku: ['SKU', 'SKU'],
    barcode: ['Barcode', 'باركود'],
    add_another_product_option: ['Add another product option', 'إضافة خيار جديد'],
    upload_product_option_photo: ['Upload product option photo', 'رفع صورة للخيار'],
    upload_product_photo: ['Upload product photo', ' رفع صورة للمنتج'],
    product_image: ['Product image', 'صورة المنتج'],
    product_option_image: ['Product option image', ' صورة خيار المنتج'],
    product_activeness_edited_successfully: ['Product activeness edited successfully', 'تم تعديل الظهور المنتج بنجاح'],
    available_for_sales: ['Available for sales', 'متاح للبيع بالتطبيق'],
    not_available_for_sales: ['Not available for sales', 'غير متاح للبيع بالتطبيق'],
    // add appointments
    paid_with_cash: ['Paid with cash', 'مدفوعة كاش'],
    paid_with_card: ['Paid with card', 'مدفوعة بالبطاقة'],
    save_unpaid: ['Save unpaid', 'حفظ غير مدفوعة'],
    save_paid: ['Save paid', ' حفظ مدفوعة'],
    add: ['Add', 'إضافة'],
    walkin: ['Walk in', 'حضور بالمركز'],
    add_service: ['Add service', 'إضافة خدمة'],
    add_product: ['Add product', 'إضافة منتج'],
    product_saved: ['Product Saved', 'تم حفظ المنتج'],
    save: ['Save', 'حفظ'],
    client_location: ['Client location', ' في مكان العميلة'],
    undefined: ['Undefined', 'غير محدد'],
    action: ['Action', 'الاجراءات'],
    actions: ['Actions', 'الاجراءات'],
    duration: ['Duration', ' مدة التقديم'],
    active: ['Active', 'نشط'],
    all_services: ['All Services', 'كل الخدمات'],
    name: ['Name', 'الاسم'],
    cancel_appointment: ['Cancel', 'إلغاء الطلب'],
    apply: ['Apply', 'تطبيق'],
    search_by_calendar: ['Search by calendar', 'البحث بالتاريخ'],
    yesterday: ['Yesterday', 'امس'],
    this_week: ['This week', 'هذا الاسبوع'],
    last_week: ['Last week', 'الاسبوع الماضي'],
    this_month: ['This month', 'هذا الشهر'],
    last_month: ['Last month', 'الشهر الماضي'],
    days_upto_today: ['Days upto today', 'كل الايام لحد اليوم'],
    days_starting_today: ['Days starting today', 'كل الايام بداية من اليوم'],

    // edit appointment
    edit_appointment: ['Edit appointment', 'تعديل الموعد'],
    refund_appointment: ['Refund appoitment', 'إسترجاع'],

    confirm_button: ['Confirm', 'تأكيد'],
    confirmed_button: ['Confirmed', ' موعد مؤكد'],
    how_did_the_client_pay: ['How did the client pay?', 'كيف تم الدفع؟'],
    completed: ['Completed ', 'طلب مكتمل'],
    partial_refund: ['Partial refund', 'استرجاع جزء من الطلب '],
    full_refund: ['Full refund', 'استرجاع كامل الطلب'],
    checkout: ['Check out', 'الذهاب للدفع'],
    choose_method_of_refund: ['Choose method of refund ', 'الرجاء إختيار طريقة الإسترجاع'],
    please_select_only_the_items_you_want_to_refund_from_this_table: [
      'Please select only the items you want to refund from this table',
      'الرجاء تحديد المراد إسترجاعة من هذا لجدول',
    ],

    // wallet container

    wallet_balance: ['Wallet balance', 'رصيد المحفظة'],

    jamelah_commission: ['Jamelah commission', 'عمولة الإلغاء'],
    commission_type: ['Commission type', 'نوع العمولة'],
    automatically_deducted: [
      'will be automatically deducted from each order',
      'سوف يتم  احتسابها تلقائياً من قيمة كل طلب',
    ],

    // off days

    add_offday: ['Add off-day', 'إضافة فترة جديدة'],

    // side menu translations
    main_menu: ['Main Menu', 'القائمة الرئيسية'],
    dash_board: ['Dashboard', 'الرئيسية'],
    calendar: ['Calendar', 'التقويم'],
    appointments: ['Appointments', 'المواعيد'],
    sales: ['Sales', 'المبيعات'],
    services: ['Services', 'الخدمات'],
    products: ['Products', 'المنتجات'],
    clients: ['Clients', 'العملاء'],
    employees: ['Employees', 'الموظفات'],
    jamelah_app: ['Jamelah app', 'تطبيق جميلة'],
    jamelah_orders: ['Jamelah orders', 'طلبات التطبيق'],
    jamelah_order: ['Jamelah order', 'إشعار طلب من التطبيق'],
    check_now: ['Check now', 'معاينة الطلب'],
    photos: ['Photos', 'الصور'],
    working_hours: ['Working hours', 'اوقات العمل'],
    off_days: ['Off days', 'ايام محظورة'],
    discount_codes: ['Discount codes', 'اكواد الخصم'],
    reviews: ['Reviews', 'المراجعات'],
    reports: ['Reports', 'التقارير'],
    settings: ['Settings', 'الإعدادات'],
    wallet: ['Wallet', 'المحفظة'],
    provider_services: ['Provider services', 'خدمات المقدمين'],
    provider_products: ['Provider products', 'منتجات المقدمين'],
    main_services: ['Main services', 'الخدمات الرئيسية'],
    top_cities_and_providers: ['Top cities and providers', 'فرز المدن والمقدمين'],
    provider_titles: ['Provider titles', 'مسميات المقدمين'],
    dynamic_links: ['Dynamic links', 'روابط متغيرة'],
    gifts: ['Gifts', 'الهدايا'],
    transactions: ['Transactions', 'العمليات المالية'],
    commission_settings: ['Commission settings', 'إعدادات العمولة'],
    offers: ['Offers', 'العروض الترويجية'],
    messages: ['Messages', 'الرسائل'],

    delivery_charge: ['Products delivery fee', 'رسوم توصيل منتجات'],
    subtotal: ['Subtotal', 'المجموع'],
    total: ['Total', 'المجموع الكلي'],
    code_applied: ['Code applied', 'كود تخفيض'],
    quantity: ['Quantity', 'العدد'],
    product_details: ['Product details', 'تفاصيل المنتج'],
    expires_at: ['Expires at', 'ينتهي في'],
    id: ['Id', 'رقم الطلب'],
    at_your_location: ['At your location', 'في مكانك'],
    created_by: ['Created by', 'تم إنشائة بواسطة'],
    appointment_details: ['Appointment details', ' تفاصيل الطلب'],
    with: ['With', 'مع'],
    your_services: ['Your services', 'خدماتك'],
    app_services: ['App services', 'خدمات التطبيق'],
    // service_categories: ['Service Categories', 'تصنيفات الخدمات'],
    add_category: ['Add Category', 'إضافة تصنيف'],
    edit_category: ['Edit Category', 'تعديل تصنيف'],
    service_name_en: ['Service name (English)', 'اسم الخدمة بالانجليزي'],
    service_name_ar: ['Service name (Arabic)', 'اسم الخدمة بالعربي'],
    enter_category_name_ar: ['Enter category name in Arabic', 'تعديل اسم التصنيف العربي'],
    enter_category_name_en: ['Enter category name in English', 'تعديل اسم التصنيف الانجليزي'],

    category_name_ar: ['Category name(Arabic)', 'اسم التصنيف بالعربي'],
    category_name_en: ['Category name(English)', 'اسم التصنيف بالانجليزي'],
    are_you_sure_to_delete_this_service_category: [
      'Are you sure to delete this service category ?',
      'هل انتي متأكدة من حذف الخدمة؟',
    ],
    products_delivery_fee: ['Products delivery fee', 'رسوم توصيل منتجات'],
    services_delivery_fee: ['Services delivery fee', 'رسوم توصيل خدمات'],

    // add appointment
    new_appointment: ['New Appointment', 'موعد جديد'],

    // vacations
    vacations: ['Vacations', 'الإجازات'],
    edit_vacation: ['Edit vacation', 'تعديل الإجازة'],
    vacation_type: ['Vacation type', 'نوع الإجازة'],
    remaining: ['remaining', 'المتبقي'],
    add_new_vacation: ['Add new', 'إضافة إجازة'],
    are_you_sure_to_delete_this_vacation: [
      'Are you sure to delete this vacation ?',
      'هل انتي متأكدة من حذف هذه الإجازة؟',
    ],
    please_select_an_employee: ['Please select an employee', 'الرجاء إختيار موظفه'],
    enter_vacation_type: ['Enter vacation type', 'إدخال نوع الاجازة'],
    add_vacation: ['Add vacation', ' إضافة إجازة'],
    // client container
    number_of_orders: ['Number of orders', 'الطلبات المنفذة'],

    // add client
    client_information: ['Client Information', 'معلومات العميل'],
    email_address: ['Email address', 'الإيميل'],
    note_optional: ['Note (optional)', 'ملاحظة (اختياري)'],
    add_new_client: ['Add new client', 'إضافة عميل جديد'],
    add_client: ['Add new', 'إضافة عميل'],
    enter_client_name: ['Enter client name', 'ادخلي اسم العميل'],
    enter_client_email: ['Enter client email', 'ادخلي ايميل العميل'],
    add_notes_here: ['Add notes here', 'اضيفي الملاحظات هنا'],
    edit_client: ['Edit client', 'تعديل عميل'],

    // employee container
    delete: ['Delete', 'حذف'],
    sun: ['Sunday', 'الأحد'],
    mon: ['Monday', 'الإثنين'],
    tue: ['Tuesday', 'الثلاثاء'],
    wed: ['Wednesday', 'الأربعاء'],
    thu: ['Thursday', 'الخميس'],
    fri: ['Friday', 'يوم الجمعة'],
    sat: ['Saturday', 'يوم السبت'],
    to: ['to', 'إلى'],
    commission: ['Commission', 'العمولة'],
    balance: ['Balance', 'الرصيد'],
    nationality: ['Nationality', 'الجنسية'],
    insurance_expiry: ['Insurance expiry', 'تاريخ إنتهاء التأمين'],
    iqama_expiry: ['Iqama expiry', ' تاريخ إنتهاء الإقامة'],
    hours: ['Hours', 'الساعات'],
    artist_employee: ['Artist Employee', 'عاملة'],
    driver: ['Driver', 'سائق'],
    doctor: ['Doctor', 'دكتور'],
    artist: ['Artist', 'فنان'],
    service_provider: ['Service provider', 'مقدم خدمة'],
    co_ordinator: ['Co-ordinator', 'منسقة مواعيد'],
    add_employee: ['Add employee', 'إضافة عميل'],
    service_income: ['Service Income', ' دخل خدمة'],

    // add employee
    add_new_employee: ['Add new employee', 'إضافة موظف جديد'],
    employee_information: ['Employee information', 'معلومات الموظف'],
    role: ['Role', 'نوع الوظيفة'],
    country: ['Country', 'الدولة'],
    insurance_expiry_date_optional: ['Insurance expiry date(optional)', ' تاريخ إنتهاء التأمين (اختياري)'],
    iqama_expiry_date_optional: ['Iqama expiry date(optional)', 'تاريخ إنتهاء الإقامة (اختياري)'],
    commission_percentage: ['Commission percentage', 'نسبة العمولة'],
    upload_employee_photo: ['Upload employee photo', 'رفع صورة للموظفة'],
    employee_picture: ['Employee picture', 'صورة الموظفة'],
    choose_employee_working_hours: ['Choose employee working hours', 'اختيار ساعات عمل الموظف'],
    note_that_users_cannt_book_the_employee_out_of_these_times: [
      'Note that users cant book the employee out of these times',
      'الرجاء ملاحظة ان المستخدمين لا يستطيعون حجز هذا الموظف في التطبيق خارج هذه الاوقات',
    ],

    // edit employee
    edit_employee: ['Edit employee', ' تعديل الموظف'],

    // status

    completed_and_paid: ['Completed and Paid', 'طلب مكتمل ومدفوع'],
    completed_and_unpaid: ['Completed and Unpaid', 'طلب مكتمل غير مدفوع'],
    booked: ['Booked', 'محجوز'],
    'no-show': ['No Show ', 'العميلة لم تحضر'],
    paidPayment: ['Paid Payment', 'طلب مدفوع'],
    cancelled: ['Cancelled', 'طلب ملغى'],
    // refund: ['Refund', 'إسترجاع'],
    cancel: ['Cancel', 'إلغاء'],
    void: ['Void', 'حذف الطلب'],
    // paid_and_confirmed: ['Paid and confirmed', 'مدفوع ومؤكد'],
    providers_joined_by_you: ['Providers joined by you', 'المقدمين المنضمين بواسطتك'],
    // login container

    welcome_to_jamelah: ['Welcome to Jamelah web', 'حياك في جميلة'],
    please_sign_in: ['Please sign in', 'الرجاء تسجيل الدخول'],
    provider: ['Provider', 'مقدم خدمة'],
    employee: ['Employee', 'موظفة'],
    where_do_you_work: ['Where do you work', 'جهة العمل'],
    enter_your_mobile_number: ['Enter your mobile number', 'الرجاء إدخال رقم جوالك'],
    please_note_that_number_cannot_be_changed: [
      'Please note that you cant change your number later, so make sure to enter the right mobile number to receive the verification code',
      'الرجاء ملاحظة انه لايمكن تغيير الرقم لاحقاً الرجاء التأكد من إدخال الرقم الخاص بالعمل وإدخاله بشكل صحيح لاستقبال كود التحقق.',
    ],
    creating_an_account_means: ['Creating an account means you’re okay with our', 'بالتسجيل انتي توافقي على'],
    terms_of_service: ['Terms of service', 'الشروط والاحكام'],
    thank_you_for_your_interest: [
      'Thank you for your interest in joining Jamelah providers',
      'شكراً لاهتمامك بالانضمام لمقدمين الخدمة في جميلة',
    ],
    please_fill_the_following_form: [
      'Please fill the following form to register in Jamelah app and request to join as a provider and benefit from Jamelah web system.',
      'الرجاء تعبئة النموذج للتسجيل في تطبيق جميلة وطلب الانضمام لمقدمين الخدمة والاستفادة من نظام جميلة للويب.',
    ],
    verify: ['Verify', 'تحقق'],
    verification: ['Verification', 'التحقق'],
    information: ['Information', 'إدخال المعلومات'],
    finish: ['Finish', 'الانتهاء'],
    send_request: ['Send request', 'إرسال الطلب'],
    enter_commercial_registration_number: [
      'Please enter commercial registration number if you are registering your company, or national ID number for individual',
      'الرجاء إدخال رقم السجل التجاري للمؤسسات او رقم الهوية الوطنية للأفراد.',
    ],
    id_or_cr_number: ['ID/CR Number', 'السجل التجاري / الهوية الوطنية'],
    request_sent_successfully: ['Request sent successfully', 'تم إرسال الطلب بنجاح'],
    you_can_contact_us_at: [
      'We will review your request and get back to you shortly, for any inquiries you can contact us at',
      'سيقوم الفريق المختص بمراجعة طلبكم والتواصل معكم لإكمال الإجراءات وتفعيل حسابكم لأي استفسارات يمكنكم التواصل معنا على',
    ],
    login: ['Login', 'دخول'],
    resend_the_code: ['Resend the code ?', 'إعادة إرسال الكود؟'],
    dont_have_an_account: ['Dont have an account ?', 'لايوجد لديك حساب!'],
    request_to_join: ['Request to join', 'إنشاء حساب جديد'],
    please_accept_the_terms_and_conditons: ['Please accept the terms and conditions', 'الرجاء قبول الشروط والاحكام'],
    select_city: ['Select city', 'ارجاء اختيار مدينتك'],
    enter_your_instagram_account_name: ['Enter your instagram account name', 'الرجاء إدخال حساب انستغرام'],
    please_enter_your_insta_name_without_link: [
      'Please enter your Instagram name without link',
      'الرجاء إدخال اسم حساب انستغرام فقط بدون رابط.',
    ],
    full_name: ['Full name', 'الاسم'],
    language: ['Language', 'اللغة'],
    please_enter_business_name: [
      'Please enter Business name for companies or your name for individuals',
      'الرجاء إدخال اسم النشاط للمؤسسات او الاسم الشخصي للافراد.',
    ],
    // please_enter_the_name: ['Please enter the name', 'الرجاء إدخال الاسم'],
    please_enter_your_id: ['Please enter your ID', 'الرجاء إدخال الهوية / السجل'],
    please_enter_your_email: ['Please enter your email', 'الرجاء إدخال الايميل'],
    please_select_your_city: ['Please select your city', 'الرجاء إختيار المدينة'],
    // offer_discount: ['Offer discount', 'خصم العرض'],
    loading: ['Loading', 'قيد التحميل'],
    your_account_is_not_approved: ['Your account is not approved', 'عذراً، حسابك غير مفعل'],
    please_enter_verification_code: [
      'Please enter verification code',
      'الرجاء إدخال كود التحقق المرسل على جوالك المسجل لدينا',
    ],
    enter_verification_code: ['Enter verification code', 'كود التحقق'],
    sign_in: ['Sign in', 'دخول'],
    chat: ['Chat', 'محادثة'],
    vat_settings: ['VAT settings', 'إعدادات الضريبة المضافة'],
    vat_settings_h: ['VAT SETTINGS', 'إعدادات الضريبة المضافة'],
    on: ['On', 'مفعله'],
    off: ['Off', 'غير مفعلة'],
    you_have_entered_incorrect_otp: ['You have entered incorrect otp', 'رقم التحقق المدخل غير صحيح'],
    please_enter_the_tin: ['Please enter the TIN', 'الرجاء إدخال الرقم الضريبي'],
    vat_fifteen_percent: ['VAT 15%', 'الضريبة 15%'],
    this_settings_will_be_applied_to_jamelah_app_and_web: [
      'This settings will be applied to Jamelah app and web',
      'سيتم تطيبق الاعدادات على الويب وتطبيق جميلة',
    ],
    vat_on: ['VAT 15% on', 'الضريبة مفعلة'],
    vat_off: ['VAT 15% off', 'الضريبة غير مفعلة'],
    taxpayer_identification_number: ['Tax payer identification number', 'الرقم الضريبي'],
    vat_collection_method: ['VAT collection method', 'طريقة إحتساب الضريبة'],
    enter_tin_number: ['Enter TIN number', 'الرجاء إدخال الرقم الضريبي'],
    choose_this_to_add_fifteen_percent_to_service_and_product: [
      'Choose this to add VAT 15% to your services and products.',
      'هذا الخيار لإضافة 15% قيمة الضريبة المضافة لمنتجاتك وخدماتك.',
    ],
    choose_this_if_fifteen_percent_already_added_to_service_and_product: [
      'Choose this if the VAT already included in your services and products prices.',
      'هذا الخيار إذا كانت اسعارك شاملة القيمة المضافة',
    ],
    prices_include_vat: ['Prices include VAT', 'الاسعار تشمل قيمة الضريبة المضافة'],
    add_fifteen_percent_vat: ['Add 15% VAT', 'إضافة 15%'],
    applied: ['Prices include VAT', 'الاسعار شاملة قيمة الضريبة المضافة'],
    tin: ['TIN', 'الرقم الضريبي'],

    // message container
    type_your_message: ['Type your message ...', 'اكتبي رسالتك هنا'],
    no_messages_found: ['No messages found', 'لا يوجد رسائل'],
    go_to_messages: ['Go to messages', 'الذهاب إلى الرسائل'],
    new_message_recieved: ['New message recieved', 'رسالة جديدة'],

    // expenses container
    expenses: ['Expenses', 'المصروفات'],
    expense: ['expense', 'مصروف'],
    added: ['added', 'اضاف'],
    edited: ['edited', 'عدّل'],
    deleted: ['deleted', 'حذف'],
    expense_categories: ['Expense categories', 'تصنيفات المصروفات'],
    categories: ['Categories', 'التصنيفات'],
    expense_category: ['Expense category', ' التصنيف'],
    activity_log: ['Activity log', 'سجل العمليات'],
    date: ['Date', 'التاريخ'],
    number: ['Number', 'الرقم'],
    add_new_expense: ['Add new expense', 'إضافة'],
    // created_date: ['Created date', 'تاريخ الإضافة'],
    expense_information: ['Expense information', 'المعلومات'],
    attach_reciept: ['Attach reciept', ' إرفاق الإيصال'],
    are_you_sure_to_delete_this_expense: ['Are you sure to delete this expense ?', 'هل انتي متأكدة من الحذف؟'],
    expense_detail: ['Expense detail', 'التفاصيل'],
    attached_reciept: ['Attached reciept', 'إرفاق الإيصال'],
    no_reciept_found: ['No attachments', 'لم يتم إرفاق إيصال'],
    edit_expense: ['Edit expense', 'تعديل'],
    enter_the_description: ['Enter the description', 'إضافة الوصف'],
    search_by_amount: ['Search by amount', 'بحث بالمبلغ'],

    // request status
    waitingartistresp: ['Waiting for provider response', 'بانتظار رد مقدم الخدمة'],
    usercancelledbeforeartistaccept: ['User cancelled', 'العميلة الغت الطلب قبل القبول'],
    usercancellednewtime: ['User cancelled new time', 'العميلة الغت طلب تغيير الموعد'],
    useracceptednewtime: ['User accepted new time', 'العميلة قبلت بالوقت المقترح وبإنتظار الدفع'],
    usercancelledpayment: ['User cancelled', 'لم يتم قبول الوقت المقترح وتم الغاء الطلب'],
    usercancelledafterpayment: ['User cancelled after payment', 'العميلة الغت الطلب قبل القبول'],
    acceptedwaitingpayment: ['Accepted and waiting for payment', 'مقبول وبإنتظار الدفع'],
    suggestednewtime: ['Suggested new time', 'وقت جديد مقترح من مقدم الخدمة'],
    artistcancelledafterpayment: ['Provider cancelled after payment', 'تم إلغاء الطلب بعد الدفع من قبل مقدم الخدمة'],
    artistcancelled: ['Provider cancelled', 'تم الإلغاء من قبل مقدم الخدمة'],
    usercancelled: ['User cancelled', 'العميلة قامت بإلغاء الطلب'],
    admincancelled: ['Cancelled by customer service', 'طلب ملغى بواسطة خدمة العملاء'],
    paidpayment: ['Paid payment', 'طلب مدفوع'],
    paidandconfirmed: ['Paid and confirmed', 'مدفوع ومؤكد'],
    inprogress: ['Inprogress', 'يتم تقديم الخدمة الآن'],
    partiallyrefunded: ['Partially refunded', 'تم استرداد جزء من المبلغ'],
    confirmed: ['Confirmed appointment', 'موعد مؤكد'],

    expirednouserresp: ['Expired -No user response', 'منتهي لعدم رد العميلة'],
    expirednopayment: ['Expired without payment', 'منتهي لعدم الدفع'],
    expirednoartistresp: ['Expired no provider response', 'منتهي لعدم رد المقدم'],

    reschedulerequested: ['Reshedule requested', 'طلب إعادة جدولة بإنتظار رد مقدم الخدمة'],
    reschedulerequestaccepted: ['Reshedule request accepted', 'طلب تغيير الموعد مقبول'],
    reschedulerequestrejected: ['Reshedule request rejected', 'تم رفض تغيير الموعد'],
    reschedulerequestcancelled: ['Reshedule request cancelled', 'تم إلغاء طلب تغيير الموعد'],
    reschedulerequestexpired: ['Reshedule request expired', 'طلب تغيير الموعدانتهى بدون رد'],
    shipped: ['Shipped', 'تم الشحن'],
    delivered: ['Delivered', 'تم التوصيل'],
    request_reschedule: ['Request reschedule', 'طلب تغيير الموعد'],
    provider_location_percentage: ['Provider Location Percentage', 'نسبة الاستقطاع للطلبات بمكان المقدم '],
    user_location_percentage: ['User Location Percentage', 'نسبة الاستقطاع للطلبات المنزلية'],
  },
};

export function getTranslation(_key, rtl) {
  const lang = !rtl ? 0 : 1;
  let key;
  let text;
  try {
    key = _key
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/\./g, '');
    text = data.resources[key][lang];
  } catch (e) {
    // console.log('error', e);
    // text = '';
    text = _key;
  }
  return text;
}

export function getBothTranslations(key) {
  // console.log('getBothTranslations')
  return data.resources[key];
}

export function getTranslationColumnName(_key) {
  // console.log('getTranslationColumnName', _key);
  const lang = getItem('language') === 'english' ? 1 : 0;
  const key = _key.toLowerCase().replace(/ /g, '_');
  let text;
  try {
    text = data.resources[key][lang];
  } catch (e) {
    // console.log('error', e);
    text = _key;
  }
  return text.toUpperCase();
}
