/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import Product from '../../container/products';

const Transactions = lazy(() => import('../../container/transactions/transactionsContainer'));
const ProductDetail = lazy(() => import('../../container/products/productDetail'));

const ProductEdit = lazy(() => import('../../container/products/editProduct'));
const AddProduct = lazy(() => import('../../container/products/addProduct'));
const MarketerDetailPage = lazy(() => import('../../container/users/marketer/marketerDetail'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Transactions} />
      <Route path={`${path}/detail/:id`} component={ProductDetail} />
      <Route path={`${path}/edit/:id`} component={ProductEdit} />
      <Route path={`${path}/add`} component={AddProduct} />
    </Switch>
  );
};

export default DashboardRoutes;
