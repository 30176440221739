import React from 'react';
import { Col } from 'antd';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <div style={{textAlign:'center',verticalAlign:'middle',marginTop:'13vh'}}>
        <Col>
          <WraperContent />
          </Col>
      </div>
    );
  };
};

export default AuthLayout;
