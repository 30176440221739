import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Titles = lazy(() => import('../../container/artistTitles/index'));
const ProductDetail = lazy(() => import('../../container/products/productDetail'));

const ProductEdit =lazy(() => import('../../container/products/editProduct'))
const AddProduct =lazy(() => import('../../container/products/addProduct'))

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Titles} />
      <Route path={`${path}/detail/:id`} component={ProductDetail} />
      <Route path={`${path}/edit/:id`} component={ProductEdit} />
      <Route path={`${path}/add`} component={AddProduct} />
    </Switch>
  );
};

export default DashboardRoutes;
