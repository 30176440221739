/*eslint-disable*/
import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Rate } from 'antd';
import { Sidebar, SidebarSingle } from './style';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { Slider } from '../../components/slider/slider';
import { CheckboxGroup } from '../../components/checkbox/checkbox';
import { getTranslation } from '../../translations';

const Filters = data => {
  console.log('categories', data);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const { categories, onChangeCategory, onChangeRating, onPriceChange, max, min, categoryIndex } = data;

  let totalProducts = 0;
  for (let i = 0; i < categories.length; i++) {
    totalProducts += categories[i].count;
  }

  const options = [
    {
      label: (
        <>
          <span className="rating-left">
            <Rate allowHalf defaultValue={5} disabled />
          </span>
          <span className="rating-right">25</span>
        </>
      ),
      value: 5,
    },
    {
      label: (
        <>
          <span className="rating-left">
            <Rate allowHalf defaultValue={4} disabled />
            {getTranslation('and_up', rtl)}
          </span>
          <span className="rating-right">25</span>
        </>
      ),
      value: 4,
    },
    {
      label: (
        <>
          <span className="rating-left">
            <Rate allowHalf defaultValue={3} disabled />
            {getTranslation('and_up', rtl)}
          </span>
          <span className="rating-right">25</span>
        </>
      ),
      value: 3,
    },
    {
      label: (
        <>
          <span className="rating-left">
            <Rate allowHalf defaultValue={2} disabled />
            {getTranslation('and_up', rtl)}
          </span>
          <span className="rating-right">25</span>
        </>
      ),
      value: 2,
    },
    {
      label: (
        <>
          <span className="rating-left">
            <Rate allowHalf defaultValue={1} disabled />
            {getTranslation('and_up', rtl)}
          </span>
          <span className="rating-right">25</span>
        </>
      ),
      value: 1,
    },
  ];

  //   const optionsBrand =categories.map((category)=>{
  // return({

  //     label: (
  //       <>
  //       {category.nameEn}
  //         {/* Cup <span className="brand-count">25</span> */}
  //       </>
  //     ),
  //     value: category.id

  // })
  //   })

  // const optionsBrand = [
  //   {
  //     label: (
  //       <>
  //         Cup <span className="brand-count">25</span>
  //       </>
  //     ),
  //     value: 'cup',
  //   },
  //   {
  //     label: (
  //       <>
  //         Plate <span className="brand-count">25</span>
  //       </>
  //     ),
  //     value: 'plate',
  //   },
  //   {
  //     label: (
  //       <>
  //         Chair <span className="brand-count">25</span>
  //       </>
  //     ),
  //     value: 'chair',
  //   },
  //   {
  //     label: (
  //       <>
  //         Juice <span className="brand-count">25</span>
  //       </>
  //     ),
  //     value: 'juice',
  //   },
  // ];

  let categoryClassForAll = '';

  categoryClassForAll = categoryIndex === '-1' ? 'active' : 'inactive';

  return (
    <Sidebar>
      <Cards
        title={
          <span>
            <FeatherIcon icon="sliders" size={14} />
            {getTranslation('filters', rtl)}
          </span>
        }
      >
        <SidebarSingle style={{ marginBottom: 32 }}>
          <Heading as="h5">{getTranslation('price_range', rtl)}</Heading>
          <Slider max={1500} onAfterChange={onPriceChange} range defaultValues={[min, max]} />
          <p className="price-range-text">
            {min} {getTranslation('sar', rtl)} - {max} {getTranslation('sar', rtl)}
          </p>
        </SidebarSingle>
        <SidebarSingle style={{ marginBottom: 32 }}>
          <Heading as="h5">{getTranslation('category', rtl)}</Heading>

          <nav>
            <ul className="atbd-category-list">
              <li>
                <Link className={categoryClassForAll} onClick={() => onChangeCategory('-1')} to="#">
                  <span>{getTranslation('all', rtl)}</span>
                  <span className="category-count">{totalProducts}</span>
                </Link>
              </li>
              {categories.map(category => {
                let categoryClass = '';
                categoryClass = categoryIndex === category.id ? 'active' : 'inactive';

                return (
                  <li>
                    <Link className={categoryClass} onClick={() => onChangeCategory(category.id)} to="#">
                      <span>{rtl ? category.nameAr : category.nameEn}</span>
                      <span className="category-count">{category.count}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          {/* <div className="sidebar-single__action">
            <Link className="btn-seeMore" to="#">
              See more
            </Link>
          </div> */}
        </SidebarSingle>

        {/* <SidebarSingle style={{ marginBottom: 32 }}>
          <Heading as="h5">Brands</Heading>
          <CheckboxGroup options={optionsBrand} onChange={onChangeBrand} />

          <div className="sidebar-single__action">
            <Link className="btn-seeMore" to="#">
              See more
            </Link>
          </div>
        </SidebarSingle> */}

        <SidebarSingle>
          <Heading as="h5">{getTranslation('ratings', rtl)}</Heading>
          <CheckboxGroup options={options} onChange={onChangeRating} />
        </SidebarSingle>
      </Cards>
    </Sidebar>
  );
};

export default Filters;
