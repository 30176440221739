import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ServiceCategories = lazy(() => import('../../container/links/index'));
const AddArticle =lazy(()=>import('../../container/links/addArticle'))
const AppointmentDetailPage =lazy(() => import('../../container/orders/appointmentDetail'))
const MarketerDetailPage =lazy(() => import('../../container/users/marketer/marketerDetail'))

const CategoriesRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ServiceCategories} />
      <Route path={`${path}/title`} component={ServiceCategories} />
      <Route path={`${path}/articles`} component={ServiceCategories} />
      <Route path={`${path}/add`} component={AddArticle} />
      <Route path={`${path}/about_us`} component={ServiceCategories} />
      <Route path={`${path}/terms`} component={ServiceCategories} />
      <Route path={`${path}/edit`} component={AddArticle} />
      <Route path={`${path}/appointment/:id`} component={AppointmentDetailPage} />
      <Route path={`${path}/marketer-detail`} component={MarketerDetailPage} />
    </Switch>
  );
};

export default CategoriesRoutes;
