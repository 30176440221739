/*eslint-disable*/
import React, { useState } from 'react';
import { Col, Rate, Switch, Row, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { ProductCard } from '../style';
// import { updateWishList } from '../../../redux/product/actionCreator';
import * as Constants from '../../../constants';
import { getTranslation } from '../../../translations';
import { DataService } from '../../../config/dataService/dataService';

const ProductCards = ({ product }) => {
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const {
    id,
    productNameAr,
    productType,
    artistId,
    productNameEn,
    productPrice,
    productQuantity,
    averageRating,
    isActive,
    productImage,
    productReviews,
  } = product;
  const [productActive, setProductActive] = useState(isActive);

  const success = data => {
    message.success(data);
  };

  const editProductActive = (profileActive, id) => {
    console.log('isActive', profileActive);
    let data = {};
    const endPoint = `product/${id}/isActive?${Constants.API_KEY}`;

    data = {
      isActive: profileActive,
    };

    // console.log(data);
    // return;

    DataService.put(endPoint, data)
      .then(result => {
        setProductActive(profileActive);

        success(getTranslation('product_activeness_edited_successfully', rtl));
      })
      .catch(error => {
        setProductActive(!profileActive);

        // console.log('error',error);
      });
  };

  return (
    <ProductCard style={{ marginBottom: 30 }}>
      <Link to={`/admin/products/detail/${id}`}>
        <figure>
          <img style={{ height: '250px' }} src={productImage} alt="img" />
        </figure>
      </Link>
      <figcaption>
        <Heading className="product-single-title" as="h5">
          <Link to={`/admin/products/detail/${id}`}>{rtl ? productNameAr : productNameEn}</Link>
        </Heading>
        <Link to={`/admin/products/detail/${id}`}>
          <p className="product-single-price">
            <span className="total-reviews">{artistId.name}</span>
          </p>
        </Link>
        <p className="product-single-price">
          <span className="product-single-price__new">
            {productPrice} {getTranslation('sar', rtl)}
          </span>
          <span className="total-reviews">
            {' '}
            {productQuantity} {getTranslation('in_stock', rtl)}
          </span>

          {/* {oldPrice && (
            <>
              <del className="product-single-price__old"> ${oldPrice} </del>
              <span className="product-single-price__offer"> 60% Off</span>
            </>
          )} */}
        </p>
        <div className="product-single-rating">
          <Rate allowHalf defaultValue={averageRating} disabled /> {averageRating}
          <span className="total-reviews">
            {' '}
            {productReviews.length} {getTranslation('reviews', rtl)}
          </span>
        </div>

        {/* <div className="product-single-action"> */}
        <Row style={{ padding: '10px 2px' }}>
          <Col sm={12} xs={12}>
            <NavLink
              to={{
                pathname: `/admin/products/edit/${id}`,
              }}
            >
              <Button size="small" type="white" className="btn-cart" outlined>
                <FeatherIcon icon="edit" size={14} />
                {getTranslation('edit', rtl)}
              </Button>
            </NavLink>
          </Col>
          <Col sm={12} xs={12}>
            <div className="active-button">
              <Switch defaultChecked={productActive} onClick={e => editProductActive(e, id)} />
            </div>
            <div className="active-button">
              {productActive
                ? getTranslation('available_to_get_in_app', rtl)
                : getTranslation('not_available_to_get_in_app', rtl)}
            </div>
          </Col>
        </Row>

        {/* </div> */}
      </figcaption>
    </ProductCard>
  );
};

ProductCards.propTypes = {
  product: PropTypes.object,
};

export default ProductCards;
