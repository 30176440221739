import React, { Suspense,lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Users from './users';
import Orders from './orders';
import Codes from './codes';
import Expenses from './expenses';
import Inventory from './inventory';
import Services from './services';
import MainServices from './mainServices'
import Cities from './cities'
import Products from './products';
import Categories from './categories';
import Titles from './titles';
import Gifts from './gifts';
import Reviews from './reviews'
import Notifications from './notifications'
import Links from './links'
import Transactions from './transactions'
import Wallet from './wallet'
import Commission from './commission'
import Offers from './offers'
import withAdminLayout from '../../layout/withAdminLayout';

const Messages = lazy(() => import('../../container/chat/chatContainer'));
const UserMessages = lazy(() => import('../../container/chat/userMessagesContainer'));
const Reports = lazy(() => import('../../container/reports/index'));
const OrdersReport = lazy(() => import('../../container/reports/ordersReport'));
const GiftOrdersReport = lazy(() => import('../../container/reports/giftOrderReport'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/reports/order-reports`} component={OrdersReport}/>
        <Route path={`${path}/reports/gift-orders`} component={GiftOrdersReport}/>

        <Route path={`${path}/report`} component={Reports}/>
        <Route path={`${path}/messages/support`} component={Messages}/>
        <Route path={`${path}/messages/list`} component={UserMessages}/>
        <Route path={`${path}/orders`} component={Orders}/>
        <Route path={`${path}/codes`} component={Codes}/>
        <Route path={`${path}/expenses`} component={Expenses} />
        <Route path={`${path}/inventory`} component={Inventory} />
        <Route path={`${path}/services`} component={Services}/>
        <Route path={`${path}/main-services`} component={MainServices}/>
        <Route path={`${path}/cities`} component={Cities}/>
        <Route path={`${path}/titles`} component={Titles}/>
        <Route path={`${path}/reviews`} component={Reviews}/>
        <Route path={`${path}/notifications`} component={Notifications}/>
        <Route path={`${path}/links`} component={Links}/>
        <Route path={`${path}/gifts`}  component={Gifts}/>
        <Route path={`${path}/products`} component={Products}/>
        <Route path={`${path}/categories`} component={Categories}/>
        <Route path={`${path}/transactions`} component={Transactions}/>
        <Route path={`${path}/wallet`} component={Wallet}/>
        <Route path={`${path}/commission`} component={Commission}/>
        <Route path={`${path}/offers`} component={Offers}/>
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
