/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DataService } from '../../config/dataService/dataService';
import { useSelector } from 'react-redux';
import { Row, Col, Radio, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { getTranslation } from '../../translations';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { TopToolBox } from './style';
// import { sorting } from '../../../redux/product/actionCreator';
import { Button } from '../../components/buttons/buttons';
import * as Constants from '../../constants';
import Filters from './Filters';
import Grid from './Grid';
// const List = lazy(() => import('./List'));

const Product = () => {
  const { rtl } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      loggedInUserId: state.auth.userData.userId,
    };
  });
  const searchData = useSelector(state => state.headerSearchData);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [state, setState] = useState({
    notData: searchData,
    active: 'active',
    totalProducts: 0,
    pageNumber: 1,
    totalPages: 0,
    limit: 25,
    sortBy: 'rate',
    min: 0,
    max: 1500,
    selectedCategory: '-1',
    selectedRatings: '[]',
    search: '',
  });

  const handleSearch = searchText => {
    console.log('search', searchText);
    setState({
      ...state,
      search: searchText,
    });
  };

  const onChangeCategory = e => {
    setState({ ...state, pageNumber: 1, selectedCategory: e });
  };

  const onChangeRating = checkValue => {
    setState({ ...state, selectedRatings: JSON.stringify(checkValue) });
    console.log(checkValue);
  };

  const onHandlePageChange = (pageNumber, limit) => {
    console.log('page', pageNumber, limit);
    // You can create pagination in here
    setProducts([]);
    setState({ ...state, pageNumber, limit });
  };

  const onShowSizeChange = (pageNumber, limit) => {
    console.log('size', pageNumber, limit);
    setProducts([]);
    setState({ ...state, pageNumber: 1, limit });
  };

  const onSearch = () => {
    setSearch(true);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      setSearch(true);
    }
  };

  useEffect(() => {
    // console.log('componentDidMount');
    fetchApiResponse();
  }, []);

  useEffect(() => {
    // console.log('componentDidMount');
    fetchProducts();
  }, [
    search,
    state.limit,
    state.pageNumber,
    state.sortBy,
    state.selectedCategory,
    state.max,
    state.min,
    state.selectedRatings,
  ]);

  const fetchApiResponse = async () => {
    setIsLoading(true);
    await fetchCategoriesData();
    await fetchProducts();
  };

  const fetchCategoriesData = () => {
    const endpoint = `productCategory/getCategoriesArtist?${Constants.API_KEY}`;

    DataService.get(endpoint)
      .then(result => {
        setCategories(result.data.categories);
      })
      .catch(error => {
        setCategories([]);
        setIsLoading(false);
        // console.log('error', error);
      });
  };

  const fetchProducts = () => {
    setIsProductListLoading(true);
    const endpoint = `product/admin-new?${Constants.API_KEY}&limit=${state.limit}&pageNo=${state.pageNumber}&sortBy=${state.sortBy}&category=${state.selectedCategory}&maxPrice=${state.max}&minPrice=${state.min}&ratingArray=${state.selectedRatings}&searchProduct=${state.search}`;

    DataService.get(endpoint)
      .then(result => {
        console.log('api works');
        setState({
          ...state,
          totalProducts: result.data.totalProducts,
          pageNumber: result.data.currentPage,
          totalPages: result.data.totalPages,
        });
        setProducts(result.data.products);
        setIsLoading(false);
        setIsProductListLoading(false);
        setSearch(false);
      })
      .catch(error => {
        setProducts([]);
        setIsLoading(false);
        setIsProductListLoading(false);

        // console.log('error', error);
      });
  };

  const onSorting = e => {
    setState({ ...state, sortBy: e.target.value });
    console.log('sort', e.target.value);
  };

  const onPriceChange = value => {
    console.log(value);
    setState({
      ...state,
      min: value[0],
      max: value[1],
    });
  };

  return (
    <>
      <PageHeader
        ghost
        title={getTranslation('products', rtl)}
        buttons={[
          <div key="1" className="page-header-actions">
            {/* <CalendarButtonPageHeader key="1" />
            <ExportButtonPageHeader key="2" />
            <ShareButtonPageHeader key="3" /> */}
            <NavLink
              to={{
                pathname: `/admin/products/add`,
              }}
            >
              <Button size="small" type="primary">
                <FeatherIcon icon="plus" size={14} />
                {getTranslation('add_product', rtl)}
              </Button>
            </NavLink>
          </div>,
        ]}
      />

      <Main>
        {isLoading ? (
          <Col xs={24}>
            <div className="spin">
              <Spin />
            </div>
          </Col>
        ) : (
          <Row gutter={30}>
            <Col className="product-sidebar-col" xxl={5} xl={7} lg={7} md={10} xs={24}>
              <Filters
                categories={categories}
                onChangeRating={onChangeRating}
                onChangeCategory={onChangeCategory}
                max={state.max}
                min={state.min}
                categoryIndex={state.selectedCategory}
                onPriceChange={onPriceChange}
              />
            </Col>
            <Col className="product-content-col" xxl={19} lg={17} md={14} xs={24}>
              <TopToolBox>
                <Row gutter={0}>
                  <Col xxl={7} lg={12} xs={24}>
                    <AutoComplete
                      submit={onSearch}
                      onSearch={key => {
                        handleSearch(key);
                      }}
                      onKeyPress={handleKeyPress}
                      dataSource={[]}
                      placeholder={getTranslation('search_by_name', rtl)}
                      width="100%"
                      patterns
                      clearButton
                    />
                  </Col>
                  <Col xxl={7} lg={12} xs={24}>
                    <p className="search-result">
                      {getTranslation('showing', rtl)} {state.pageNumber * state.limit - state.limit}–
                      {state.pageNumber * state.limit} {getTranslation('of', rtl)} {state.totalProducts}{' '}
                      {getTranslation('products', rtl)}
                    </p>
                  </Col>
                  <Col xxl={10} xs={24}>
                    <div className="product-list-action d-flex justify-content-between align-items-center">
                      <div className="product-list-action__tab">
                        {/* <span className="toolbox-menu-title"> Status:</span> */}
                        <Radio.Group onChange={onSorting} defaultValue="rate">
                          <Radio.Button value="rate">{getTranslation('top_rated', rtl)}</Radio.Button>
                          <Radio.Button value="time">{getTranslation('newest', rtl)}</Radio.Button>
                          <Radio.Button value="price">{getTranslation('price', rtl)}</Radio.Button>
                        </Radio.Group>
                      </div>
                      {/* {(window.innerWidth <= 991 && window.innerWidth >= 768) ||
                      (window.innerWidth > 575 && (
                        <div className="product-list-action__viewmode">
                          <NavLink to={`${path}/grid`}>
                            <FeatherIcon icon="grid" size={16} />
                          </NavLink>
                          <NavLink to={`${path}/list`}>
                            <FeatherIcon icon="list" size={16} />
                          </NavLink>
                        </div>
                      ))} */}
                    </div>
                  </Col>
                </Row>
              </TopToolBox>

              {/* <Switch>
              <Suspense
                fallback={
                  <div className="spin d-flex align-center-v">
                    <Spin />
                  </div>
                }
              > */}
              <Grid
                products={products}
                isLoader={isProductListLoading}
                pageNumber={state.pageNumber}
                limit={state.limit}
                totalPages={state.totalPages}
                onHandleChange={onHandlePageChange}
                onShowSizeChange={onShowSizeChange}
                totalProducts={state.totalProducts}
              />
              {/* <Route exact path={path} component={Grid} /> */}
              {/* <Route exact path={`${path}/grid`} component={Grid} /> */}
              {/* <Route exact path={`${path}/list`} component={List} /> */}
              {/* </Suspense>
            </Switch> */}
            </Col>
          </Row>
        )}
      </Main>
    </>
  );
};

export default Product;
