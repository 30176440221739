/*eslint-disable*/
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';

import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import Support from './support';
import { setItem } from '../../../utility/localStorageControl';
import { changeRtlMode } from '../../../redux/themeLayout/actionCreator';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { getTranslation } from '../../../translations';
import * as Constants from '../../../constants';

const AuthInfo = ({ changeRtlMode }) => {
  const dispatch = useDispatch();

  const { loggedInUserData, rtl } = useSelector(state => {
    return {
      loggedInUserData: state.auth.userData,
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });

  const flag = rtl ? 'saudi' : 'english';

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };
  const getTranslationOfRole = role => {
    // console.log('logged', loggedInUserData);
    let text;
    switch (role) {
      case Constants.ARTIST:
        text = getTranslation('service_provider', rtl);
        break;
      case Constants.CO_ORDINATOR:
        text = getTranslation('co_ordinator', rtl);
        break;
      case Constants.DRIVER:
        text = getTranslation('driver', rtl);
        break;
      case Constants.DOCTOR:
        text = getTranslation('doctor', rtl);
        break;
      case Constants.ARTIST_EMPLOYEE:
        text = getTranslation('artist_employee', rtl);
        break;
      case Constants.MARKETER:
        text = getTranslation('marketer', rtl);
        break;
      case Constants.ADMIN:
        text = getTranslation('admin', rtl);
        break;
      default:
        text = getTranslation('artist', rtl);
    }
    return text;
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {/* <img src={loggedInUserData.profilePicture} alt="" /> */}
          <figcaption>
            <Heading as="h5">{loggedInUserData.name}</Heading>
            <p>{getTranslationOfRole(loggedInUserData.role)}</p>
          </figcaption>
        </figure>
        {(loggedInUserData.role === Constants.ARTIST || loggedInUserData.role === Constants.CO_ORDINATOR) && (
          <ul className="user-dropdwon__links">
            {/* <li>
            <Link to="/admin/settings">
              <FeatherIcon icon="settings" /> {getTranslation('settings',rtl)}
            </Link>
          </li> */}
            {/* <li>
            <Link to="/admin/messages">
              <FeatherIcon icon="user" /> messages
            </Link>
          </li>
          <li>
            <Link to="/admin/notifications">
              <FeatherIcon icon="user" /> notifications
            </Link>
          </li> */}
            {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li> */}
            {/* <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
          </ul>
        )}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> {getTranslation('signout', rtl)}
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    const rtl = value !== 'english';
    setItem('language', value);
    // setState({
    //   ...state,
    //   flag: 'english',
    // });
    changeRtlMode(rtl);
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img style={{ height: '30px', width: '40px' }} src={require('../../../static/img/flag/english.svg')} alt="" />
        <span>{getTranslation('english', rtl)}</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('arabic')} to="#">
        <img style={{ height: '30px', width: '40px' }} src={require('../../../static/img/flag/saudi.svg')} alt="" />
        <span>{getTranslation('arabic', rtl)}</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* {<Message />} */}
      {/* {(loggedInUserData.role===Constants.ARTIST ||loggedInUserData.role===Constants.CO_ORDINATOR)&&
      <Notification />
      } */}

      {/* <Settings />
      <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img style={{ height: '20px' }} src={require(`../../../static/img/flag/${flag}.svg`)} alt="" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={require(`../../../static/img/logo.png`)} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};
const mapStateToProps = state => {
  return {
    rtl: state.ChangeLayoutMode.rtlData,
  };
};

AuthInfo.propTypes = {
  changeRtlMode: propTypes.func.isRequired,
};

export default connect(mapStateToProps, { changeRtlMode })(AuthInfo);
