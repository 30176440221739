import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/users/usersContainer'));
const ArtistDetailPage =lazy(() => import('../../container/users/artistDetail'))
const UserDetailPage =lazy(() => import('../../container/users/userDetails'))
const MarketerDetailPage =lazy(() => import('../../container/users/marketer/marketerDetail'))
const AddMarketer=lazy(()=>import('../../container/users/marketer/addMarketer'))
const AddAgent=lazy(()=>import('../../container/users/agent/addAgent'))

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/agents`} component={Dashboard} />
      <Route exact path={`${path}/providers`} component={Dashboard} />
      <Route exact path={`${path}/marketers`} component={Dashboard} />
      <Route exact path={`${path}/users`} component={Dashboard} />

      <Route path={`${path}/detail`} component={ArtistDetailPage} />
      <Route path={`${path}/add-marketer`} component={AddMarketer} />
      <Route path={`${path}/add-agent`} component={AddAgent} />

      <Route path={`${path}/user-detail`} component={UserDetailPage} />
      <Route path={`${path}/marketer-detail`} component={MarketerDetailPage} />
    </Switch>
  );
};

export default DashboardRoutes;
