import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ServiceCategories = lazy(() => import('../../container/offers/index'));
const CodeDetailPage =lazy(() => import('../../container/discountCodes/discountCodeDetail'))
const ProviderCodeDetailPage=lazy(() => import('../../container/discountCodes/providerDiscountCodeDetail'))
const AddOffer = lazy(()=>import('../../container/offers/addOffer'))
const EditOffer=lazy(()=>import('../../container/offers/editOffer'))
const EditPromotionalCoupon=lazy(()=>import('../../container/discountCodes/editPromotionalCode'))

const CategoriesRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ServiceCategories} />
      <Route path={`${path}/list`} component={ServiceCategories} />
      <Route path={`${path}/categories`} component={ServiceCategories} />
      <Route path={`${path}/product`} component={ServiceCategories} />

      <Route path={`${path}/add`} component={AddOffer} />
      <Route path={`${path}/edit/:id`} component={EditOffer} />
      <Route path={`${path}/edit-promo/:id`} component={EditPromotionalCoupon} />
      <Route path={`${path}/detail/:id`} component={CodeDetailPage} />
      <Route path={`${path}/code-detail/:id`} component={ProviderCodeDetailPage} />
     
    </Switch>
  );
};

export default CategoriesRoutes;
