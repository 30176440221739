import axios from 'axios';
import { clearToken, getItem } from '../../utility/localStorageControl';
import Config from '../config';

const API_ENDPOINT = process.env.REACT_APP_BACKEND_API_URL || Config.API_LOCAL_URL;

const authHeader = () => ({
  // Authorization: `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGVObyI6OTY2NTA5MTIzNzY3LCJuYW1lIjoiTmV3IEFydGlzdCIsInJvbGUiOiJBcnRpc3QiLCJfaWQiOiI1ZTVhZWI3ODEwODQ3YTJhYjlhZWZkYTQiLCJpYXQiOjE2MTEwNDkzNjh9.kHyn3VQ6RTaT_gfZRBXlzGLGXOd8S4b-RahugVxHH0Y'}`
  Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data) {
    return client({
      method: 'PUT',
      url: path,
      data: data,
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    // const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else if (response.status === 401 || response.status === 403) {
        console.log('Un-authenticated');
        clearToken();
        window.location.reload();
      }
      // else {
      //   return originalRequest;
      // }
    }
    return Promise.reject(error);
  },
);
export { DataService };
