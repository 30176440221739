import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getTranslation } from '../translations';
import * as Constants from '../constants';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const { rtl, loggedInUserRole } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      loggedInUserRole: state.auth.userData.role,
    };
  });
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        key="home"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}`}>
              <FeatherIcon icon="home" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          {getTranslation('dashboard', rtl)}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        key="user"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/users/users`}>
              <FeatherIcon icon="users" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/users/users`}>
          {getTranslation('users', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="orders"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/orders`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
          {getTranslation('orders', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="services"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/services`}>
              <FeatherIcon icon="scissors" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/services`}>
          {getTranslation('provider_services', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="products"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/products`}>
              <FeatherIcon icon="shopping-cart" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/products`}>
          {getTranslation('provider_products', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="mainServices"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/main-services`}>
              <FeatherIcon icon="briefcase" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/main-services`}>
          {getTranslation('main_services', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="cities"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/cities`}>
              <FeatherIcon icon="bookmark" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/cities`}>
          {getTranslation('top_cities_and_providers', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="categories"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/categories/service`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/categories/service`}>
          {getTranslation('categories', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="report"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/report`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/report`}>
          {getTranslation('reports', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="codes"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/codes`}>
              <FeatherIcon icon="aperture" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/codes`}>
          {getTranslation('codes', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="titles"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/titles`}>
              <FeatherIcon icon="clipboard" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/titles`}>
          {getTranslation('provider_titles', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="reviews"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/reviews/service`}>
              <FeatherIcon icon="star" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/reviews/service`}>
          {getTranslation('reviews', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="notifications"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/notifications/history`}>
              <FeatherIcon icon="bell" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/notifications/history`}>
          {getTranslation('notifications', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="links"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/links/title`}>
              <FeatherIcon icon="link" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/links/title`}>
          {getTranslation('dynamic_links', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="gifts"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/gifts/order`}>
              <FeatherIcon icon="gift" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/gifts/order`}>
          {getTranslation('gifts', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="transactions"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/transactions`}>
              <FeatherIcon icon="dollar-sign" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/transactions`}>
          {getTranslation('transactions', rtl)}
        </NavLink>
      </Menu.Item>
      {loggedInUserRole === Constants.ADMIN && (
        <Menu.Item
          key="wallet"
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/wallet/providers`}>
                <FeatherIcon icon="credit-card" />
              </NavLink>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/wallet/providers`}>
            {getTranslation('wallets', rtl)}
          </NavLink>
        </Menu.Item>
      )}

      <Menu.Item
        key="percentage"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/commission/service`}>
              <FeatherIcon icon="percent" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/commission/service`}>
          {getTranslation('commission_settings', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="offers"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/offers/list`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/offers/list`}>
          {getTranslation('offers', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key="messages"
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/messages/support`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/messages/support`}>
          {getTranslation('messages', rtl)}
        </NavLink>
      </Menu.Item>

      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/inventory`}>
              <FeatherIcon icon="database" />
            </NavLink>
          )
        }
        key="inventory"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/inventory`}>
          {getTranslation('inventory', rtl)}
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/expenses`}>
              <FeatherIcon icon="briefcase" />
            </NavLink>
          )
        }
        key="expenses"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/expenses`}>
          {getTranslation('expenses', rtl)}
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
