import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';


const Services = lazy(() => import('../../container/expenses/expenseContainer'));
const OrderDetail = lazy(() => import('../../container/expenses/expenseDetail'));
const AddService = lazy(() => import('../../container/expenses/addExpense'));
const EditExpense = lazy(() => import('../../container/expenses/editExpense'));
const ServiceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Services} />
      <Route path={`${path}/detail/:id`} component={OrderDetail} />
      <Route path={`${path}/add`} component={AddService} />
      <Route path={`${path}/edit`} component={EditExpense} />

    </Switch>
  );
};

export default ServiceRoutes;
