import React from 'react';
import { Row, Col, Pagination, Spin } from 'antd';
import { useSelector } from 'react-redux';
import ProductCards from './overview/ProductCards';
import Heading from '../../components/heading/heading';
import { NotFoundWrapper,PaginationWrapper } from './style';
import { getTranslation } from '../../translations';
import { ConfigProvider } from 'antd';
import arEG from 'antd/lib/locale/ar_EG';

const Grid = (props) => {
    const {products,isLoader,totalProducts,onHandleChange,limit,onShowSizeChange,pageNumber}=props
    const rtl = useSelector(state =>
      state.ChangeLayoutMode.rtlData
    );
  return (
    //   <h1>hii</h1>
    <Row gutter={30}>
      {isLoader ? (
        <Col xs={24}>
          <div className="spin">
            <Spin />
          </div>
        </Col>
      ) : products.length ? (
        products.map((product,index) => {
          return (
            <Col xxl={6} lg={12} xs={24} key={index}>
              <ProductCards product={product} />
            </Col>
          );
        })
      ) : (
        <Col md={24}>
          <NotFoundWrapper>
            <Heading as="h1">{getTranslation('data_not_found',rtl)}</Heading>
          </NotFoundWrapper>
        </Col>
      )}
      <Col xs={24} className="pb-30">
        <PaginationWrapper style={{ marginTop: 10 }}>
        {products.length ? (
          rtl?
           <ConfigProvider locale={arEG}>
            <Pagination
              onChange={onHandleChange}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSize={limit}
              defaultCurrent={pageNumber}
              total={totalProducts}
            />
            </ConfigProvider>:
            <Pagination
            onChange={onHandleChange}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSize={limit}
            defaultCurrent={pageNumber}
            total={totalProducts}
          />
          ) : null}
        </PaginationWrapper>
      </Col>
    </Row>
  );
};

export default Grid;
